import React, { useContext, useState, useEffect } from "react";
import walletAdd from "../icons/wallet-add.svg";
import bank from "../icons/bank.svg";
import wallet from "../icons/empty-wallet.svg";
import right from "../icons/right.svg";
import add from "../icons/add.svg";
import arrow from "../icons/arrow-2.svg";
import refresh from "../icons/refresh-2.svg";
import eye from "../icons/eyew.svg";
import eyeclosed from "../icons/eye-blockedw.svg";
import wifi from "../icons/wifi.svg";
import phone from "../icons/call-calling.svg";
import devices from "../icons/devices.svg";
import flash from "../icons/flash.svg";
import eclipse1 from "../icons/eclipse1.svg";
import eclipse2 from "../icons/eclipse2.svg";
import mobile from "../icons/mobile.svg";
import headphone from "../icons/headphone.svg";
import close from "../icons/Close.svg";
import info from "../icons/info-circle.svg";
import Nav from "../components/nav";
import playstore from "../icons/playstore.png";
import applestore from "../icons/applestore.png";
import { ReactComponent as RightAngle } from "../icons/rightarrowdark.svg";
import loadingSmall from "../icons/loadingSmall.svg";
import { useHistory, Link } from "react-router-dom";
import { UserContext } from "../providers/userData";
import { AppDataContext } from "../providers/appData";
import { FormContext } from "../providers/formValues";
import { getOS } from "../helper/getOs";
import { toast } from "react-toastify";
import Alert from "../components/Alert";
import copy from "../icons/copy.svg";
import Layout from "../Layout";

function Selffix() {
  const { user, userDispatch } = useContext(UserContext);
  const { appData, dispatch } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [sending, setSending] = useState(false);
  const [sendingDel, setSendingDel] = useState({ deleting: false, index: 0 });
  const [showform, setShowform] = useState(false);
  const [focused, setFocused] = useState(false);

  document.title = "Self Fix Funding" + appData.business.name;

  return (
    <Layout PageName={"Self-Fix Wallet Funding"}>
      <div className="px-0 py-8">
        <div className=" font-medium text-sm   ">
          <div>
            <p className="mb-3 text-dark mt-4 text-center">
              Did you fund your wallet and it is yet to reflect? Resolve your
              complaint yourself and save time!
            </p>

            <div className="mt-5  ">
              <h2 className="font-medium md:text-2xl md:mt-6 dark:text-white ">
                Select the Funding Method
              </h2>
              <div className=" grid grid-cols-2 lg:grid-cols-5 gap-3  lg:gap-3   mt-3  md:mt-4 ">
                <Link to="selffixAutoagent">
                  <button className="bg-primary-black w-full flex  rounded-lg   justify-start items-center  py-2 px-4  md:flex-col md:items-start md:basis-1/3 ">
                    <span className="inline-flex  items-center p-1 border bg-primary-orange  border-primary-orange rounded-md ">
                      <img
                        src={walletAdd}
                        alt="wallet"
                        className=" bg-primary-orange md:h-11 md:w-11 "
                      />
                    </span>
                    <h4 className="min-w-max font-medium text-sm md:text-lg leading-3 ml-3 md:ml-0 text-white flex flex-col justify-start items-start md:mt-14 md:py-4">
                      <p>AutoAgent </p>
                    </h4>
                  </button>
                </Link>
                {/* <Link to="data">
                  <button className="bg-primary-black w-full flex  rounded-lg   justify-start items-center  py-2 px-4  md:flex-col md:items-start md:basis-1/3 ">
                    <span className="inline-flex  items-center p-1 border bg-primary-orange  border-primary-orange rounded-md ">
                      <img
                        src={wifi}
                        alt="wifi"
                        className=" bg-primary-orange md:h-11 md:w-11 "
                      />
                    </span>
                    <h4 className="min-w-max font-medium text-sm md:text-lg leading-3 ml-3 md:ml-0 text-white flex flex-col justify-start items-start md:mt-14 md:py-4">
                      <p>Monnify </p>
                    </h4>
                  </button>
                </Link> */}
                {/* <Link to="airtime">
                  <button className="bg-primary-black w-full flex  rounded-lg   justify-start items-center  py-2 px-4  md:flex-col md:items-start md:basis-1/3 ">
                    <span className="inline-flex  items-center p-1 border bg-primary-orange  border-primary-orange rounded-md">
                      <img
                        src={phone}
                        alt="phone"
                        className=" bg-primary-orange md:h-11 md:w-11 "
                      />
                    </span>

                    <h4 className="min-w-max font-medium text-sm md:text-lg leading-3 ml-3 md:ml-0 text-white flex flex-col justify-start items-start md:mt-14 md:py-4">
                      <p>ATM</p>
                    </h4>
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Selffix;
