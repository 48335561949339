import React, { useContext, useEffect, useState } from "react";
import eisalat from "../icons/9mobile.png";
import glo from "../icons/glo.png";
import airtel from "../icons/airtel.png";
import mtn from "../icons/mtn.png";
import { getOS } from "../helper/getOs";
import CurrencyFormat from "../helper/CurrencyFormat";
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "../providers/auth";
import { FormContext } from "../providers/formValues";
import { AppDataContext } from "../providers/appData";
import PlansModels from "../components/plansModels";
import NumberCheckModal from "../components/numberCheckModal";
import { UserContext } from "../providers/userData";
import PaymentType from "../components/paymentType";
import ConfirmationModel from "../components/confirmationModel";
import Layout from "../Layout";
import alert from "../icons/alert.svg";
import DisableNotification from "../components/DisableNotification";
import phonebook from "../icons/phonebook.png";
import ContactModal from "../components/ContactModal";
import Api from "../../src/helper/Api";

function DataPurchase() {
  const { setShowModal, showModal } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [networkErro, setnetworkErro] = useState("");
  const [planerro, setPlanerro] = useState("");
  const [focused, setFocused] = useState(false);
  const [CloseNotice, setCloseNotice] = useState(false);
  const [Notice, setNotice] = useState("");
  const [sending, setSending] = useState(false);

  document.title = "Purchase Data-" + appData.business.name;

  // Function  to enroll tip! check for device capability before enrollment
  const getPhoneNumber = () => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "getPhoneNumber" })
      );
  };

  const handleFocus = (e) => {
    setFocused(true);
  };
  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
    document
      .getElementById("phone_number")
      .addEventListener("input", function () {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "value_reciever_number",
            value: this.value.replace(/\D/g, ""),
          },
        });
      });
  }, []);

  const onPlanSelect = (network, atmPrice, walletPrice) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "network", value: network },
    });
  };

  // console.log(formData.ref);
  var networkPrefix = {
    MTN: [
      "0803",
      "0703",
      "0903",
      "0806",
      "0706",
      "0813",
      "0810",
      "0814",
      "0816",
      "0906",
      "0704",
      "0916",
      "0911",
      "0707",
      "0907",
      "0913",
    ],
    GLO: ["0805", "0705", "0905", "0807", "0815", "0811", "0905", "0915"],
    AIRTEL: ["0802", "0902", "0701", "0808", "0708", "0812", "0901", "0907"],
    ETISALAT: ["0809", "0909", "0817", "0818", "0908"],
  };

  const history = useHistory();

  const onValidSubmit = (e) => {
    e.preventDefault();
    if (!formData.network) {
      return setnetworkErro("Please pick a network!");
    } else if (!formData.plans_name) {
      return setPlanerro("Please pick a paln!");
    }
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "ConfirmationModal",
        value: {
          isOpen: true,
          type: "Data Subscription",
          description: formData.plans_name,
          receiver: formData.value_reciever_number,
          amount: formData.atmPayment
            ? formData.atmPrice
            : formData.walletPrice,
          network: formData.network,
        },
      },
    });
  };

  const NumberCheck = () => {
    const prefix = networkPrefix[formData.network];
    const isValidNumber = prefix.some((p) =>
      formData.value_reciever_number.startsWith(p)
    );
    if (isValidNumber) {
      process();
    } else {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "NumberCheckModal",
          value: {
            isOpen: true,
            type: "Data Subscription",
          },
        },
      });
    }
  };

  function onSuccess(data) {
    setSending(false);
    if (formData.atmPayment) {
      window.location = data.data.payment_url;
      return;
    }
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: { isOpen: true, message: data.message, show: true },
      },
    });
  }

  function onFail(error) {
    setSending(false);
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "ref", value: Math.random().toString(36).slice(2) },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: {
          isOpen: true,
          message: error,
          type: "error",
        },
      },
    });
  }

  const process = () => {
    setSending(true);
    Api(
      "post",
      "data_purchase",
      {
        plan_id: formData.plans_id,
        phone_number: formData.value_reciever_number,
        payment_method: formData.atmPayment ? "ATM" : "WALLET",
        ref: formData.ref,
        password: formData.password,
      },
      onSuccess,
      onFail,
      user.token,
      history
    );
  };
  useEffect(() => {
    if (formData.fromBeneficiaries) {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "fromBeneficiaries",
          value: false,
        },
      });
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "plans_id", value: "" },
      });
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "plans_name", value: "" },
      });
      setShowModal((preShowModel) => !preShowModel);
      return;
    }
  }, []);

  return (
    <Layout PageName={"Data Purchase"}>
      <div className="">
        <h2 className="font-medium text-sm mt-4 md:text-lg">Select Network</h2>
        {CloseNotice && (
          <DisableNotification Close={setCloseNotice} notification={Notice} />
        )}
        <ContactModal />
        {formData.ConfirmationModal && (
          <div>
            <ConfirmationModel onConfirmationClicked={NumberCheck} />
          </div>
        )}
        {formData.NumberCheckModal && (
          <NumberCheckModal onConfirmationClicked={process} />
        )}
        <div className="grid grid-cols-4 gap-6 mt-2">
          <button
            className="flex  flex-col justify-center items-center  "
            onClick={() => {
              appData.master_settings.MTN_data_master * 1 === 0
                ? onPlanSelect(
                    "MTN",
                    appData.airtime_plans.MTN.atm_price,
                    appData.airtime_plans.MTN.wallet_price
                  )
                : setCloseNotice(true);

              setNotice("MTN Data is not available now please try again later");

              setnetworkErro("");
            }}
          >
            <div
              onClick={() => {
                appData.master_settings.MTN_data_master * 1 === 0
                  ? setShowModal(!showModal)
                  : setShowModal(false);
              }}
              className={
                formData.network === "MTN"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.MTN_data_master * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img
                src={mtn}
                alt="mtn"
                className="md:h-28 p-1 bg-white rounded-lg"
              />
            </div>
            {/* <div className=" font-extrabold text-xs md:text-base mt-2.5">
              MTN
            </div> */}
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.GLO_data_master * 1 === 0
                ? onPlanSelect(
                    "GLO",
                    appData.airtime_plans.GLO.atm_price,
                    appData.airtime_plans.GLO.wallet_price
                  )
                : setCloseNotice(true);

              setNotice("GLO Data is not available now please try again later");

              setnetworkErro("");
            }}
          >
            <div
              onClick={() => {
                appData.master_settings.GLO_data_master * 1 === 0
                  ? setShowModal(!showModal)
                  : setShowModal(false);
              }}
              className={
                formData.network === "GLO"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.GLO_data_master * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img
                src={glo}
                alt="glo"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            {/* <div className=" font-extrabold text-xs md:text-base mt-2.5">
              GLO
            </div> */}
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.AIRTEL_data_master * 1 === 0
                ? onPlanSelect(
                    "AIRTEL",
                    appData.airtime_plans.AIRTEL.atm_price,
                    appData.airtime_plans.AIRTEL.wallet_price
                  )
                : setCloseNotice(true);
              setNotice(
                "AIRTEL Data is not available now please try again later"
              );

              setnetworkErro("");
            }}
          >
            <div
              onClick={() => {
                appData.master_settings.AIRTEL_data_master * 1 === 0
                  ? setShowModal(!showModal)
                  : setShowModal(false);
              }}
              className={
                formData.network === "AIRTEL"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.AIRTEL_data_master * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img
                src={airtel}
                alt="airtel"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            {/* <div className=" font-extrabold text-xs md:text-base mt-2.5">
              Airtel
            </div> */}
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.ETISALAT_data_master * 1 === 0
                ? onPlanSelect(
                    "ETISALAT",
                    appData.airtime_plans.ETISALAT.atm_price,
                    appData.airtime_plans.ETISALAT.wallet_price
                  )
                : setCloseNotice(true);
              setNotice(
                "9mobile Data is not available now please try again later"
              );

              setnetworkErro("");
            }}
          >
            <div
              onClick={() => {
                appData.master_settings.ETISALAT_data_master * 1 === 0
                  ? setShowModal(!showModal)
                  : setShowModal(false);
              }}
              className={
                formData.network === "ETISALAT"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.ETISALAT_data_master * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img
                src={eisalat}
                alt="etisalat"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            {/* <div className=" font-extrabold text-xs md:text-base mt-2.5">
              9mobile
            </div> */}
          </button>
        </div>{" "}
        <p className="text-red-500">{networkErro}</p>
      </div>
      <form onSubmit={onValidSubmit}>
        <div className="mt-5">
          <div className="w-full space-y-6">
            <div className="w-full">
              <div className=" relative ">
                <label>
                  <div className="flex justify-between">
                    <div className="font-medium text-gray-700 dark:text-gray-200 text-sm md:text-base">
                      Data Plans
                    </div>
                    <div className="font-medium text-gray-700 dark:text-gray-200 text-sm md:text-base">
                      {user.data &&
                        `Balance: ₦ ${CurrencyFormat(
                          user.data.wallet_balance
                        )}`}
                    </div>
                  </div>
                  <input
                    type="text"
                    autoComplete="off"
                    name="select_plan"
                    focused={focused.toString()}
                    readOnly
                    disabled={!formData.network}
                    required
                    className=" rounded-lg  flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-3 "
                    placeholder="Select plan"
                    value={formData.plans_name}
                    onClick={() => {
                      setShowModal(!showModal);
                      setPlanerro("");
                    }}
                  />
                  <p className="text-red-500">{planerro}</p>
                </label>

                {showModal ? <PlansModels /> : null}
              </div>
            </div>
            <div className="w-full">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-2">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Phone Number
                        {user.data && (
                          <label
                            className="ml-1 p-1 text-white rounded-lg "
                            onClick={() => {
                              formDispatch({
                                type: "INPUTVALUES",
                                data: {
                                  name: "value_reciever_number",
                                  value: user.data.phone_number,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: appData.business.primary_color,
                            }}
                          >
                            Paste my number
                          </label>
                        )}
                      </div>
                    </div>

                    <div className="w-full">
                      <div className=" relative ">
                        <label>
                          <div
                            style={{
                              marginRight:
                                localStorage.getItem("isMobileApp") && "44px",
                            }}
                          >
                            <input
                              type="text"
                              id="phone_number"
                              className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                              placeholder="08X XXX XXXX"
                              focused={focused.toString()}
                              required
                              autoComplete="off"
                              name="value_reciever_number"
                              value={formData.value_reciever_number}
                              inputMode="numeric"
                              pattern="^[0-9]{11,11}$"
                              onBlur={handleFocus}
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                          </div>
                          {localStorage.getItem("isMobileApp") && (
                            <img
                              src={phonebook}
                              width={42}
                              alt=""
                              className="absolute right-0 bottom-12  rounded-lg  p-1 border border-slate-300 dark:bg-white "
                              style={{ right: -2, bottom: 0 }}
                              onClick={(e) => {
                                e.preventDefault();
                                getPhoneNumber();
                              }}
                            />
                          )}

                          <span>
                            Please enter a valid receiver phone number
                          </span>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
                {formData.contact_name && (
                  <p className="m-0 p-0" style={{ fontSize: 10 }}>
                    {formData.contact_name}
                  </p>
                )}
              </div>
            </div>
            <PaymentType
              sending={sending}
              setFocused={setFocused}
              focused={focused}
              handleFocus={handleFocus}
            />
          </div>
        </div>
      </form>
    </Layout>
  );
}

export default DataPurchase;
