import React, { useContext, useEffect, useState } from "react";
import { getOS } from "../helper/getOs";
import { useHistory } from "react-router-dom";
import { FormContext } from "../providers/formValues";
import { AppDataContext } from "../providers/appData";
import { UserContext } from "../providers/userData";
import Layout from "../Layout";
import PaymentType from "../components/paymentType";
import Api from "../../src/helper/Api";

function Kyc() {
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [focused, setFocused] = useState(false);
  const [sending, setSending] = useState(false);

  document.title = "KYC Form-" + appData.business.name;

  const handleFocus = (e) => {
    setFocused(true);
  };
  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
    document
      .getElementById("identifier")
      .addEventListener("input", function () {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "identifier",
            value: this.value.replace(/\D/g, ""),
          },
        });
      });

    bvnIdentity(true); //set bvn as default verification
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "firstname", value: user.data.firstname },
    });

    formDispatch({
      type: "INPUTVALUES",
      data: { name: "othername", value: user.data.othername },
    });

    formDispatch({
      type: "INPUTVALUES",
      data: { name: "lastname", value: user.data.lastname },
    });

    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  const onValidSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    Api(
      "post",
      "kyc",
      {
        identifier: formData.identifier,
        kyc_type: formData.ninIdentity ? "NIN" : "BVN",
        phone_number: formData.phone_number,
        firstname: formData.firstname,
        othername: formData.othername,
        lastname: formData.lastname,
        ref: formData.ref,
        password: formData.password,
      },
      onSuccess,
      onFail,
      user.token,
      history
    );
  };

  function onSuccess(data) {
    setSending(false);

    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: { isOpen: true, message: data.message, show: true },
      },
    });
  }

  function onFail(error) {
    setSending(false);
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "ref", value: Math.random().toString(36).slice(2) },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: {
          isOpen: true,
          message: error,
          type: "error",
        },
      },
    });
  }

  const bvnIdentity = (toggle) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "ninIdentity", value: false },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "bvnIdentity", value: toggle },
    });
  };

  const ninIdentity = (toggle) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "ninIdentity", value: toggle },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "bvnIdentity", value: false },
    });
  };

  return (
    <Layout PageName={"KYC Form"}>
      <div className="">
        <h2 className="font-medium text-sm mt-4 md:text-lg font-bold">
          Verify Your Identity{" "}
          <span class="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill="currentColor"
                d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
              />
              <path
                fill="#fff"
                d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
              />
            </svg>
          </span>
        </h2>
        <p className="font-medium text-sm md:text-xl   mt-2">
          Government policies require us to verify your identity with the
          BVN/NIN linked to your phone number. This will ensure we know who you
          are and keep your account safe. Dial *565*0# to retrieve your BVN or
          *346# to retrieve your NIN
        </p>
        <div
          className="w-full p-3 mt-4 rounded-lg  relative   lg:px-7   lg:p-10"
          style={{
            backgroundColor: `${appData.business.primary_color}20`,
          }}
        >
          <div className="flex justify-between items-center">
            <span class="inline-flex items-center mr-1 justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-200 dark:blue-red-400">
              <svg
                class="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 214.27 214.27"
              >
                <g>
                  <path
                    d="M196.926,55.171c-0.11-5.785-0.215-11.25-0.215-16.537c0-4.142-3.357-7.5-7.5-7.5c-32.075,0-56.496-9.218-76.852-29.01
		c-2.912-2.832-7.546-2.831-10.457,0c-20.354,19.792-44.771,29.01-76.844,29.01c-4.142,0-7.5,3.358-7.5,7.5
		c0,5.288-0.104,10.755-0.215,16.541c-1.028,53.836-2.436,127.567,87.331,158.682c0.796,0.276,1.626,0.414,2.456,0.414
		c0.83,0,1.661-0.138,2.456-0.414C199.36,182.741,197.954,109.008,196.926,55.171z M107.131,198.812
		c-76.987-27.967-75.823-89.232-74.79-143.351c0.062-3.248,0.122-6.396,0.164-9.482c30.04-1.268,54.062-10.371,74.626-28.285
		c20.566,17.914,44.592,27.018,74.634,28.285c0.042,3.085,0.102,6.231,0.164,9.477C182.961,109.577,184.124,170.844,107.131,198.812
		z"
                  />
                  <path
                    d="M132.958,81.082l-36.199,36.197l-15.447-15.447c-2.929-2.928-7.678-2.928-10.606,0c-2.929,2.93-2.929,7.678,0,10.607
		l20.75,20.75c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.732,5.303-2.196l41.501-41.5
		c2.93-2.929,2.93-7.678,0.001-10.606C140.636,78.154,135.887,78.153,132.958,81.082z"
                  />
                </g>
              </svg>
            </span>

            <p className="text-sm">
              Our KYC verification is safe and secure, It won't give us access
              to your bank accounts.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-6 "></div>
      </div>
      <form onSubmit={onValidSubmit}>
        <div className="mt-2">
          <div className="w-full space-y-4">
            <div className="w-full">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-0">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Firstname
                      </div>
                    </div>

                    <div className="w-full">
                      <div className=" relative ">
                        <label>
                          <div>
                            <input
                              type="text"
                              id="firstname"
                              className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                              focused={focused.toString()}
                              required
                              autoComplete="off"
                              name="firstname"
                              value={formData.firstname}
                              onBlur={handleFocus}
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-0">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Othername/Middlename
                      </div>
                    </div>

                    <div className="w-full">
                      <div className=" relative ">
                        <label>
                          <div>
                            <input
                              type="text"
                              id="othername"
                              className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                              focused={focused.toString()}
                              autoComplete="off"
                              name="othername"
                              value={formData.othername}
                              onBlur={handleFocus}
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-0">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Lastname
                      </div>
                    </div>

                    <div className="w-full">
                      <div className=" relative ">
                        <label>
                          <div>
                            <input
                              type="text"
                              id="lastname"
                              className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                              focused={focused.toString()}
                              required
                              autoComplete="off"
                              name="lastname"
                              value={formData.lastname}
                              onBlur={handleFocus}
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-0">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Phone Number
                      </div>
                    </div>

                    <div className="w-full">
                      <div className=" relative ">
                        <label>
                          <div>
                            <input
                              type="text"
                              id="phone_number"
                              className=" rounded-lg  dark:text-white  flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                              style={{
                                backgroundColor: `${appData.business.primary_color}10`,
                              }}
                              focused={focused.toString()}
                              required
                              autoComplete="off"
                              name="value_reciever_number"
                              value={user.data.phone_number}
                              inputMode="numeric"
                              pattern="^[0-9]{11,11}$"
                              onBlur={handleFocus}
                              disabled
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-0">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Identity Type
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex gap-4 item-center " style={{ marginTop: 10 }}>
              <button
                type="button"
                className={
                  "py-2 px-4 md:py-4 md:px-8 flex justify-center items-center  hover:text-white   w-full transition ease-in duration-200 border border-slate-300 text-center text-base md:text-lg font-medium shadow-md   rounded-lg " +
                  (formData.bvnIdentity
                    ? "bg-primary-orange text-white"
                    : "bg-white text-gray-700 ")
                }
                onClick={() => bvnIdentity(true)}
              >
                BVN
              </button>
              <button
                type="button"
                className={
                  "py-2 px-4 md:py-4 md:px-8 flex justify-center items-center  hover:text-white   w-full transition ease-in duration-200 border border-slate-300 text-center text-base md:text-lg font-medium shadow-md   rounded-lg " +
                  (formData.ninIdentity
                    ? "bg-primary-orange text-white"
                    : "bg-white text-gray-700")
                }
                onClick={() => ninIdentity(true)}
              >
                NIN
              </button>
            </div>

            <div className="w-full">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-0">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        {formData.bvnIdentity
                          ? " Bank Verification Number (BVN)"
                          : "National Identification Number (NIN)"}
                      </div>
                    </div>

                    <div className="w-full">
                      <div className=" relative ">
                        <label>
                          <div>
                            <input
                              type="text"
                              id="identifier"
                              className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                              focused={focused.toString()}
                              required
                              placeholder="xxxxxxxxxx"
                              autoComplete="off"
                              name="identifier"
                              value={formData.identifier}
                              inputMode="numeric"
                              onBlur={handleFocus}
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <label className="text-base md:text-lg font-medium  ">
              <i>
                {formData.bvnIdentity
                  ? user.data.kyc_verification_tries < 2
                    ? `Price: Free, ${
                        user.data.kyc_verification_tries + 1
                      } of 2 free verification attempts`
                    : "Price: ₦10"
                  : "Price: ₦140"}
              </i>
            </label>
            <PaymentType
              sending={sending}
              setFocused={setFocused}
              focused={focused}
              handleFocus={handleFocus}
              walletOnly={true}
              buttonTag="Verify Identity"
            />
          </div>
        </div>
      </form>
    </Layout>
  );
}

export default Kyc;
