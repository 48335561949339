import React, { useContext, useEffect } from "react";

import { FormContext } from "../providers/formValues";

export default function ConfirmationModel(props) {
  const { formData, formDispatch } = useContext(FormContext);

  return (
    <>
      {formData.contacts && (
        <div>
          <div
            className={
              "justify-center items-center flex overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none w-full"
            }
          >
            <div className={"relative w-full my-6 mx-auto max-w-sm  px-4 "}>
              <div className="bg-white  dark:bg-gray-900 max-w-2xl shadow overflow-hidden rounded-2xl border-t-8  border-primary-orange ">
                <h3 className="text-primary-orange text-center p-3">
                  SELECT CONTACT
                </h3>
                <div className="p-3">
                  <input
                    className=" rounded-l  mb-4 flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange   focus:   mt-1"
                    type="text"
                    name="search"
                    placeholder="search"
                    value={formData.search}
                    onChange={(e) => {
                      window.ReactNativeWebView &&
                        window.ReactNativeWebView.postMessage(
                          JSON.stringify({
                            command: "getPhoneNumber",
                            data: e.target.value,
                          })
                        );
                    }}
                  />
                  <div
                    className="overflow-y-auto"
                    style={{ maxHeight: "60vh" }}
                  >
                    {formData.contacts &&
                      JSON.parse(formData.contacts).map((contact) => {
                        return (
                          <div
                            onClick={() => {
                              formDispatch({
                                type: "INPUTVALUES",
                                data: {
                                  name: "value_reciever_number",
                                  value: contact.number,
                                },
                              });
                              formDispatch({
                                type: "INPUTVALUES",
                                data: {
                                  name: "receiver",
                                  value: contact.number,
                                },
                              });
                              formDispatch({
                                type: "INPUTVALUES",
                                data: {
                                  name: "contact_name",
                                  value: contact.name,
                                },
                              });
                              formDispatch({
                                type: "INPUTVALUES",
                                data: {
                                  name: "contacts",
                                  value: null,
                                },
                              });
                            }}
                          >
                            <p className="pt-0 mt-1 font-semibold ">
                              {contact.name}
                            </p>
                            <p
                              className="pt-0 mt-0 mb-1  "
                              style={{ fontSize: 10 }}
                            >
                              {contact.number}
                            </p>
                            <hr
                              className="bg-gray-300"
                              style={{ height: "1px", border: "none" }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <button
                  type="button"
                  className="py-3    text-white bg-primary-orange hover:bg-gray-100 focus:bg-gray-100 focus:ring-offset-indigo-200  w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg flex justify-center items-center flex-col "
                  onClick={(e) => {
                    formDispatch({
                      type: "INPUTVALUES",
                      data: {
                        name: "contacts",
                        value: null,
                      },
                    });
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black dark:bg-white"></div>
        </div>
      )}
    </>
  );
}
