import sort from "../icons/sort.svg";
import wrong from "../icons/wrong.svg";
import right from "../icons/right.svg";
import leftsAngle from "../icons/leftsAngle.svg";
import rightAngle from "../icons/rightAngle.svg";
import loadingSmall from "../icons/loadingSmall.svg";
import paying from "../icons/paying.svg";
import { ReactComponent as Wallet } from "../icons/empty-wallet.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../providers/userData";
import { AppDataContext } from "../providers/appData";
import { FormContext } from "../providers/formValues";
import { useHistory } from "react-router-dom";
import CurrencyFormat from "../helper/CurrencyFormat";
import { useReactToPrint } from "react-to-print";
import Layout from "../Layout";

function Transaction(props) {
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [Loading, setLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const ref = useRef();
  const printRef = useRef();
  const handlePrint = useReactToPrint(
    window.ReactNativeWebView
      ? {
          content: () => printRef.current,
          print: (printIframe) => {
            setPrinting(true);
            const serializer = new XMLSerializer();
            const content = serializer.serializeToString(
              printIframe.contentDocument
            );
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ command: "print", data: content })
            );
            setTimeout(function () {
              setPrinting(false);
            }, 5000);
          },
        }
      : {
          content: () => printRef.current,
        }
  );
  useEffect(() => {
    formDispatch({
      type: "CLEAR_FORM_DATA",
    });
    window.scrollTo(0, 0);
    fetchTransactions(1);
  }, []);

  const history = useHistory();
  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  const navigateToPage = (page) => {
    fetchTransactions(page);
  };

  const transactionSelect = (transaction) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "transaction", value: transaction },
    });

    formDispatch({
      type: "INPUTVALUES",
      data: { name: "modalOpen", value: true },
    });
  };

  const transactionSelectClose = (transaction) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "transaction", value: transaction },
    });

    formDispatch({
      type: "INPUTVALUES",
      data: { name: "modalOpen", value: false },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();
    fetchTransactions(1);
  };

  const fetchTransactions = (page) => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var parameter = "";
    parameter += formData.search ? "&search=" + formData.search : "";
    parameter += formData.transactionType
      ? "&transaction_type=" + formData.transactionType
      : "";
    parameter += formData.pageSize ? "&size=" + formData.pageSize : "";
    parameter += formData.dateFrom ? "&date_from=" + formData.dateFrom : "";
    parameter += formData.dateTo ? "&date_to=" + formData.dateTo : "";
    parameter += "&page=" + page;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      localStorage.getItem("apiURL") + "transactions?" + parameter,
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setLoading(false);
        if (data.status === "success") {
          const objectValues = Object.values(data.data.data);
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "transactions", value: objectValues },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "current_page", value: data.data.current_page },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "per_page", value: data.data.per_page },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "last_page", value: data.data.last_page },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "total", value: data.data.total },
          });
          setLoading(false);
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setLoading(false);
      });
  };
  const transactionStatus = [
    "Delivered",
    "Paying",
    "Queued ",
    "Queued ",
    "Processing",
    "Reversed",
    "Failed",
    "Cancelled",
  ];
  const transactionTypes = [
    "ATM Wallet Funding", //0
    "Direct Bank Funding", //1
    "Purchase Reversal", //2
    "Transfer from User", //3
    "DataNow Admin Withdrawal", //4
    "Data Purchase", //5
    "Airtime Purchase", //6
    "Transfer to user", //7
    "Cable TV Subscription", //8
    "Electricity Bill Payment", //9
    "Commission Payment", //10
    "Auto Agent Funding", //11
    "Transfer from Admin", //12
    "Referral Bonus Payment", //13
  ];
  const getDataPlanName = (planId) => {
    var planName = "";
    appData.data_plans.MTN.map((plan) => {
      if (plan.id == planId) planName = plan.name;
    });
    if (planName === "")
      appData.data_plans.GLO.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.data_plans.AIRTEL.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.data_plans.ETISALAT.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });

    return planName;
  };

  const getElectricPlanName = (planId) => {
    var planName = "";
    appData.electric_plans.IKEDC.map((plan) => {
      if (plan.id == planId) planName = plan.name;
    });
    if (planName === "")
      appData.electric_plans.PHED.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.KEDCO.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.KAEDCO.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.JED.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.IBEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.EKEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.electric_plans.AEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });

    if (planName === "")
      appData.electric_plans.EEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });

    if (planName === "")
      appData.electric_plans.BEDC.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });

    return planName;
  };

  const getCablePlanName = (planId) => {
    var planName = "";
    if (planName === "")
      appData.cable_plans.STARTIMES.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.cable_plans.DSTV.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    if (planName === "")
      appData.cable_plans.GOTV.map((plan) => {
        if (plan.id == planId) planName = plan.name;
      });
    return planName;
  };

  const getPlanTv = (planId) => {
    var tv = "";
    appData.cable_plans.STARTIMES.map((plan) => {
      if (plan.id == planId) tv = "STARTIMES";
    });
    appData.cable_plans.DSTV.map((plan) => {
      if (plan.id == planId) tv = "DSTV";
    });
    appData.cable_plans.GOTV.map((plan) => {
      if (plan.id == planId) tv = "GOTV";
    });

    return tv;
  };

  const getPlanNetwork = (planId) => {
    var network = "";
    appData.data_plans.MTN.map((plan) => {
      if (plan.id == planId) network = "MTN";
    });
    appData.data_plans.GLO.map((plan) => {
      if (plan.id == planId) network = "GLO";
    });
    appData.data_plans.AIRTEL.map((plan) => {
      if (plan.id == planId) network = "AIRTEL";
    });
    appData.data_plans.ETISALAT.map((plan) => {
      if (plan.id == planId) network = "ETISALAT";
    });
    return network;
  };

  const getPlanDisco = (planId) => {
    var disco = "";
    appData.electric_plans.IKEDC.map((plan) => {
      if (plan.id === planId) disco = "IKEDC";
    });
    appData.electric_plans.PHED.map((plan) => {
      if (plan.id == planId) disco = "PHED";
    });
    appData.electric_plans.KEDCO.map((plan) => {
      if (plan.id == planId) disco = "KEDCO";
    });
    appData.electric_plans.KAEDCO.map((plan) => {
      if (plan.id == planId) disco = "KAEDCO";
    });
    appData.electric_plans.JED.map((plan) => {
      if (plan.id == planId) disco = "JED";
    });
    appData.electric_plans.IBEDC.map((plan) => {
      if (plan.id == planId) disco = "IBEDC";
    });
    appData.electric_plans.EKEDC.map((plan) => {
      if (plan.id == planId) disco = "EKEDC";
    });
    appData.electric_plans.AEDC.map((plan) => {
      if (plan.id == planId) disco = "AEDC";
    });
    appData.electric_plans.EEDC.map((plan) => {
      if (plan.id == planId) disco = "EEDC";
    });
    appData.electric_plans.BEDC.map((plan) => {
      if (plan.id == planId) disco = "BEDC";
    });
    return disco;
  };

  const Transaction = (props) => {
    return (
      <div>
        <hr
          className="m-2 bg-gray-300"
          style={{ height: "1px", border: "none" }}
        />
        <div
          className="flex w-full mt-1.5 "
          style={{ cursor: "pointer" }}
          // ref={ref}
          onClick={() => {
            transactionSelect(props.transaction);
          }}
        >
          <div className="w-full ">
            <div className="flex w-full">
              <div className=" flex flex-col pb-0 mr-2 items-center justify-center">
                {props.transaction.status * 1 === 0 && (
                  <img src={right} alt="right" className="h-8" />
                )}

                {(props.transaction.status * 1 === 1 ||
                  props.transaction.status * 1 === 2 ||
                  props.transaction.status * 1 === 3 ||
                  props.transaction.status * 1 === 4) && (
                  <img src={paying} alt="paying" className="h-8" />
                )}
                {props.transaction.status * 1 === 5 && (
                  <Wallet
                    style={{ width: "33px", height: "33px" }}
                    fill="#00a1ff"
                  />
                )}
                {(props.transaction.status * 1 === 6 ||
                  props.transaction.status * 1 === 7) && (
                  <img src={wrong} alt="wrong" className="h-8" />
                )}
                <p className="font-medium text-xx md:text-sm">
                  {transactionStatus[props.transaction.status]}
                </p>
              </div>
              <div className="flex w-full flex-col  space-y-2">
                <div className="flex justify-between w-full">
                  <h4 className="font-medium text-sm md:text-base">
                    {transactionTypes[props.transaction.transaction_type]}
                  </h4>
                  <div className="flex items-center justify-center">
                    <p
                      className={
                        props.transaction.transaction_type > 3
                          ? props.transaction.transaction_type < 10
                            ? "text-red-500 font-medium text-sm md:text-base"
                            : "text-green-500 font-medium text-sm md:text-base"
                          : "text-green-500 font-medium text-sm md:text-base"
                      }
                    >
                      ₦{CurrencyFormat(props.transaction.amount)}
                    </p>
                    <p className=" text-xs md:text-sm">
                      /
                      {props.transaction.payment_method * 1 === 0
                        ? "WALLET"
                        : "ATM"}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between w-full">
                  <p className="font-medium text-xs md:text-sm ">
                    {props.transaction.additional_description
                      ? props.transaction.additional_description
                      : props.transaction.device_number}
                  </p>
                  <p className="font-medium text-xs md:text-sm">
                    {props.transaction.created_at}
                  </p>
                </div>
                <div className="flex justify-between w-full">
                  <p className="font-medium text-xs md:text-sm">
                    {props.transaction.description}
                  </p>

                  <p className="font-medium text-xs md:text-sm">
                    <p className="font-medium text-xs md:text-sm">
                      ₦{CurrencyFormat(props.transaction.amount_before)}/₦
                      {CurrencyFormat(props.transaction.amount_after)}
                    </p>
                  </p>
                </div>
                <div className="flex justify-between w-full">
                  <p className=""></p>
                  <p className="font-medium text-xs md:text-sm"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Layout PageName={"Transactions"}>
      <div className="flex-1 flex flex-col  mx-auto relative mb-6 md:h-full ">
        <div className="  rounded-lg  ">
          <form onSubmit={handleSubmit} className="w-full">
            <h3 className="font-medium text-sm mt-4">Search </h3>
            <div className="flex flex-col mb-2 mt-3">
              <div className="flex relative ">
                <input
                  className=" rounded-l-lg flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:  "
                  placeholder="Search for Transactions"
                  name="search"
                  value={formData.search}
                  onChange={formOnChange}
                />

                <span
                  className="rounded-r-md inline-flex items-center px-3 border-t bg-white border-r border-b  border-slate-300 text-gray-500 shadow-sm text-sm"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <img src={sort} alt="sort" />
                </span>
              </div>
            </div>

            {showFilter ? (
              <>
                <div className="flex gap-4 mb-2 mt-2.5">
                  <select
                    value={formData.transactionType}
                    name="transactionType"
                    onChange={formOnChange}
                    className="form-select  px-3 py-1.5 w-full text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out  m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-label="Default select example"
                  >
                    <option value="">All Transactions</option>
                    {transactionTypes.map((transaction, index) => {
                      return <option value={index}>{transaction}</option>;
                    })}
                  </select>

                  <select
                    name="pageSize"
                    value={formData.pageSize}
                    onChange={formOnChange}
                    className="form-select appearance-none block w-full px-3 py-1.5 text-base  font-normal text-gray-700bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-label="Default select example"
                  >
                    <option value="">15 per page</option>
                    <option value="30">30 per page</option>
                    <option value="50">50 per page</option>
                  </select>
                </div>
                <div className="flex gap-4 mb-2 ">
                  <input
                    className=" rounded-lg  flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:  "
                    type="date"
                    name="dateFrom"
                    value={formData.dateFrom}
                    onChange={formOnChange}
                  />
                  <p className="flex items-center"> TO</p>
                  <input
                    className=" rounded-lg flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:  "
                    type="date"
                    name="dateTo"
                    value={formData.dateTo}
                    onChange={formOnChange}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            <div className="flex w-full my-2">
              <button
                type="submit"
                className="py-2 px-4 bg-primary-orange  focus:ring-primary-orange  text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                onClick={() => setShowFilter(false)}
              >
                {Loading ? (
                  <div className="flex items-center justify-center">
                    <img
                      src={loadingSmall}
                      alt="loading ..."
                      className="w-7 h-7 "
                    />
                  </div>
                ) : (
                  `Apply`
                )}
              </button>
            </div>
          </form>
          {formData.transactions && (
            <div className="pb-5">
              <p className="font-medium text-sm">
                Showing (
                {formData.per_page * formData.current_page -
                  formData.per_page +
                  1}{" "}
                to{" "}
                {formData.current_page * formData.per_page -
                  formData.per_page +
                  formData.transactions.length}{" "}
                ) of {formData.total} Transactions
              </p>
              {formData.transactions.map((transaction) => {
                return (
                  <Transaction key={transaction.id} transaction={transaction} />
                );
              })}
            </div>
          )}
        </div>

        <div className=" mx-auto bottom-4 md:bottom-0 self-center  fixed bg-white dark:bg-gray-900  w-full flex items-center  justify-center">
          <div className="   mb-12 md:mb-0 mx-4   w-40 flex items-center justify-between pb-3 pt-1 ">
            <button
              className="flex items-center  justify-center h-11 w-11  rounded-md bg-primary-black text-white"
              disabled={formData.current_page > 1 ? false : true}
              onClick={() => {
                navigateToPage(formData.current_page - 1);
              }}
            >
              <img src={leftsAngle} alt="ardiv" />
            </button>
            <div className="font-medium text-xx ">
              Page {formData.current_page} of {formData.last_page}
            </div>
            <button
              className="flex items-center justify-center h-11 w-11   rounded-md bg-primary-black text-white"
              disabled={
                formData.current_page === formData.last_page ? true : false
              }
              onClick={() => {
                navigateToPage(formData.current_page + 1);
              }}
            >
              <img src={rightAngle} alt="Right" />
            </button>
          </div>
        </div>
      </div>
      {formData.transaction && formData.modalOpen ? (
        <>
          <div
            className=" fixed inset-0  bg-black/[.25]  flex justify-center items-center "
            onClick={() => {
              transactionSelectClose();
            }}
            style={{ zIndex: "1000" }}
          >
            {/*content*/}
            <div
              className="bg-white max-w-2xl opacity-100 shadow overflow-hidden rounded-2xl border-t-8  border-primary-orange 
              basis-11/12"
              style={{ maxWidth: "600px" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              ref={printRef}
            >
              <div className="text-primary-orange text-center p-3">
                <div className="   h-5 flex justify-center items-center p-4  ">
                  <img
                    src={window.location.origin + "/logo.png"}
                    alt="logo"
                    className=""
                    style={{ height: 46 }}
                  />
                  <h1 className="font-medium text-2xl text-primary-orange text-center    ">
                    {appData.business.name}
                  </h1>
                </div>
              </div>
              <div className="border-t border-gray-200">
                <p className="text-center text-lg font-medium text-black p-2 ">
                  TRANSACTION RECEIPT
                  <label>
                    <img
                      src={wrong}
                      alt="wrong"
                      style={{ float: "right" }}
                      onClick={() => {
                        transactionSelectClose();
                      }}
                    />
                  </label>
                </p>

                <dl className="border-t border-gray-200">
                  <div className="bg-white  py-2 flex flex-row px-2">
                    <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                      TYPE
                    </dt>
                    <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                      {transactionTypes[formData.transaction.transaction_type]}
                    </dd>
                  </div>
                  {formData.transaction.transaction_type * 1 === 5 ||
                  formData.transaction.transaction_type * 1 === 6 ||
                  formData.transaction.transaction_type * 1 === 8 ||
                  formData.transaction.transaction_type * 1 === 9 ? (
                    <div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          {formData.transaction.transaction_type * 1 === 5 &&
                            "DATA PLAN"}
                          {formData.transaction.transaction_type * 1 === 6 &&
                            "AIRTIME VALUE"}
                          {formData.transaction.transaction_type * 1 === 8 &&
                            "CABLE PLAN"}
                          {formData.transaction.transaction_type * 1 === 9 &&
                            "DISCO"}
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3 basis-7/12">
                          {formData.transaction.transaction_type * 1 === 5 &&
                            getPlanNetwork(formData.transaction.plan_id)}
                          {formData.transaction.transaction_type * 1 === 6 &&
                            JSON.parse(formData.transaction.metadata)[
                              "network"
                            ]}
                          {formData.transaction.transaction_type * 1 === 8 &&
                            getPlanTv(formData.transaction.plan_id)}
                          {formData.transaction.transaction_type * 1 === 9 &&
                            getPlanDisco(formData.transaction.plan_id)}
                          &nbsp;
                          {formData.transaction.transaction_type * 1 === 5 &&
                            getDataPlanName(formData.transaction.plan_id)}
                          {formData.transaction.transaction_type * 1 === 6 &&
                            "₦" +
                              CurrencyFormat(
                                JSON.parse(formData.transaction.metadata)[
                                  "airtime_value"
                                ]
                              )}
                          {formData.transaction.transaction_type * 1 === 8 &&
                            getCablePlanName(formData.transaction.plan_id)}
                          {formData.transaction.transaction_type * 1 === 9 &&
                            getElectricPlanName(formData.transaction.plan_id)}
                        </dd>
                      </div>

                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          {formData.transaction.transaction_type * 1 === 5 &&
                            "PHONE NUMBER"}
                          {formData.transaction.transaction_type * 1 === 6 &&
                            "PHONE NUMBER"}
                          {formData.transaction.transaction_type * 1 === 8 &&
                            "SMARTCARD NUMBER"}
                          {formData.transaction.transaction_type * 1 === 9 &&
                            "METER NUMBER"}
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3 basis-7/12">
                          {formData.transaction.device_number}
                        </dd>
                      </div>
                      {formData.transaction.transaction_type * 1 === 9 && (
                        <React.Fragment>
                          <div className="bg-white  py-2 flex flex-row px-2">
                            <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                              METER TYPE
                            </dt>
                            <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                              {getElectricPlanName(
                                formData.transaction.plan_id
                              )}
                            </dd>
                          </div>
                          <div className="bg-white  py-2 flex flex-row px-2">
                            <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                              CUSTOMER NAME
                            </dt>
                            <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                              {
                                JSON.parse(formData.transaction.metadata)[
                                  "customer_name"
                                ]
                              }
                            </dd>
                          </div>
                          <div className="bg-white  py-2 flex flex-row px-2">
                            <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                              CUSTOMER ADDRESS
                            </dt>
                            <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                              {
                                JSON.parse(formData.transaction.metadata)[
                                  "customer_address"
                                ]
                              }
                            </dd>
                          </div>
                          <div className="bg-white  py-2 flex flex-row px-2">
                            <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                              TOKEN
                            </dt>
                            <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                              {
                                JSON.parse(formData.transaction.metadata)[
                                  "token"
                                ]
                              }
                            </dd>
                          </div>
                          <div className="bg-white  py-2 flex flex-row px-2">
                            <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                              UNITS
                            </dt>
                            <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                              {
                                JSON.parse(formData.transaction.metadata)[
                                  "units"
                                ]
                              }{" "}
                            </dd>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          PAYMENT METHOD
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          {formData.transaction.payment_method === "0"
                            ? "ATM"
                            : "WALLET"}
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          AMOUNT
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          ₦
                          {CurrencyFormat(
                            formData.transaction.transaction_type * 1 === 9
                              ? JSON.parse(formData.transaction.metadata)[
                                  "token_value"
                                ]
                              : formData.transaction.amount
                          )}
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          DATE
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          {formData.transaction.created_at}
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          TRANSACTION ID
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          {formData.transaction.id}
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          STATUS
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          {" "}
                          {transactionStatus[formData.transaction.status]}
                        </dd>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          AMOUNT
                        </dt>
                        <dd
                          className={
                            formData.transaction.transaction_type > 3
                              ? "text-red-500"
                              : "text-green-500"
                          }
                        >
                          <p className="m-0 p-0">
                            ₦{CurrencyFormat(formData.transaction.amount)}
                          </p>
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          PAYER
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          <p className="m-0 p-0">
                            {formData.transaction.payer}
                          </p>
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          RECEIVER
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          <p className="m-0 p-0">Data Purchase</p>
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          INITIAL BALANCE
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          <p className="m-0 p-0">
                            ₦
                            {CurrencyFormat(formData.transaction.amount_before)}
                          </p>
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          BALANCE AFTER
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          <p className="m-0 p-0">
                            ₦{CurrencyFormat(formData.transaction.amount_after)}
                          </p>
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          DATE
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          <p className="m-0 p-0">
                            {formData.transaction.created_at}
                          </p>
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          STATUS
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          <p className="m-0 p-0">
                            {" "}
                            {formData.transaction.status === 0
                              ? "Successful"
                              : "Pending"}
                          </p>
                        </dd>
                      </div>
                      <div className="bg-white  py-2 flex flex-row px-2">
                        <dt className="text-sm font-medium text-gray-900 basis-5/12 ">
                          DESCRIPTION
                        </dt>
                        <dd className=" text-sm text-gray-900 mt-0 col-span-2 pl-3  basis-7/12">
                          <p className="m-0 p-0">
                            {formData.transaction.description}
                          </p>
                        </dd>
                      </div>
                    </div>
                  )}

                  <div className="flex justify-center my-4">
                    <button
                      className="ml-4 inline-flex text-white bg-primary-orange  border-0 py-2 px-12   rounded text-lg"
                      onClick={handlePrint}
                    >
                      {printing ? (
                        <div className="flex items-center justify-center">
                          <img
                            src={loadingSmall}
                            alt="loading ..."
                            className="w-7 h-7 "
                          />
                        </div>
                      ) : window.ReactNativeWebView ? (
                        "Share Receipt"
                      ) : (
                        "Print"
                      )}
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </Layout>
  );
}

export default Transaction;
