import React, { useContext, useState, useEffect } from "react";
import loadingSmall from "../icons/loadingSmall.svg";
import { FormContext } from "../providers/formValues";
import { Link, useHistory } from "react-router-dom";
import { AppDataContext } from "../providers/appData";
import {} from "react/cjs/react.development";

const ForgotPassword = () => {
  const history = useHistory();
  const { formData, formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);
  const [focused, setFocused] = useState(false);
  const [loading, setloading] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };
  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    var urlencoded = new URLSearchParams();
    urlencoded.append("identifier", String(formData.identifier));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
    setloading(true);
    fetch(localStorage.getItem("apiURL") + "forgot_password", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);

        formDispatch({
          type: "INPUTVALUES",
          data: { name: "success", value: data.message },
        });
        history.push("/resetPassword");
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setloading(false);
      });
  };

  return (
    <div className="bg-gradient-to-r w-screen h-screen py-28 flex flex-col items-center justify-center from-primary-orange to-primary-black ">
      <div className=" rounded-2xl shadow  dark:bg-gray-900 bg-white">
        <div className="   h-5 flex justify-center items-center p-6  mt-10">
          <img
            src={window.location.origin + "/logo.png"}
            alt="logo"
            className=""
            style={{ height: 46 }}
          />
          <h1 className="font-medium text-lg md:text-2xl text-primary-orange text-center    ">
            {appData.business.name}
          </h1>
        </div>
        <div className="flex flex-col max-w-sm px-4 pt-2  sm:px-6 md:px-8 lg:px-10">
          <form onSubmit={handleSubmit}>
            <div className="relative w-full mt-12">
              <label className="block  text-gray-700 text-sm font-medium mb-2">
                Email address / Phone number
                <input
                  type="text"
                  className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-3.5"
                  placeholder="Email or Phone Number"
                  focused={focused.toString()}
                  required
                  onBlur={handleFocus}
                  name="identifier"
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  value={formData.identifier}
                />
                <span>Please enter correct Email or Phone Number </span>
              </label>
            </div>
            <div className="flex w-full mt-10">
              <button
                type="submit"
                className="py-2 px-4 bg-primary-orange  active:ring-primary-orange text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-lg "
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <img
                      src={loadingSmall}
                      alt="loading ..."
                      className="w-7 h-7 "
                    />
                  </div>
                ) : (
                  ` Reset Password`
                )}
              </button>
            </div>
          </form>
          <div className="justify-center text-sm text-center text-gray-500 flex-items-center dark:text-gray-400 mt-12 mb-3">
            Remember your password?&nbsp;
            <Link to="/signin" className="text-sm  text-primary-orange  ">
              SignIn now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
