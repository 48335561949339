import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import Alert from "./components/Alert";
import BeneficiaryModal from "./components/beneficiaryModal";
import ConfirmationModel from "./components/confirmationModel";
import Nav from "./components/nav";
import NotificationModal from "./components/notificationModal";
import { AppDataContext } from "./providers/appData";
import { FormContext } from "./providers/formValues";
import { UserContext } from "./providers/userData";
import LeftAngle from "./icons/LeftAngle.svg";
import bell from "./icons/Bell.svg";

function Layout(props) {
  const { appData } = useContext(AppDataContext);

  const { formData } = useContext(FormContext);
  const { user, userDispatch } = useContext(UserContext);
  const history = useHistory();
  function next(where) {
    history.push(where);
  }
  return (
    <div className="flex ">
      {appData.settings.notification &&
        appData.settings.notification !== user.notification && (
          <NotificationModal name={props.name} />
        )}
      <div className="z-10" style={{ position: "fixed" }}>
        <Nav />
      </div>
      <div
        className="flex-1 flex flex-col items-center mx-auto md:mt-12 md:ml-72  "
        // style={{ marginLeft: "300px" }}
      >
        {formData.Alert ? <Alert message={formData.Alert.message} /> : ""}
        {formData.BeneficiaryModal ? <BeneficiaryModal /> : ""}

        <div className="p-5 flex  flex-col min-h-screen h-full w-full   rounded-lg  sm:px-6 md:px-8  relative mb-12 md:mb-0">
          <div className="flex justify-between items-center">
            <div className="flex justify-between item-center">
              <button
                onClick={() => {
                  next("/");
                }}
              >
                {/* <img src={LeftAngle} alt="leftAngle" /> */}
                <div
                  className="dark:stroke-white
                    stroke-black"
                >
                  <svg
                    width="10"
                    height="20"
                    viewBox="0 0 10 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.80111 18.1332L1.9329 11.265C1.12177 10.4539 1.12177 9.1266 1.9329 8.31548L8.80111 1.44727"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </button>
              <h2 className="ml-8 font-medium text-sm md:text-xl dark:text-white">
                {props.PageName}
              </h2>
            </div>
            <button
              type="button"
              className="button flex"
              onClick={() => {
                userDispatch({
                  type: "UPDATE_SHOW_NOTIFICATION",
                  action: false,
                });
                userDispatch({
                  type: "UPDATE_USER_NOTIFICATION",
                  action: "",
                });
              }}
            >
              {user.notification && user.showNotification && (
                <p className="md:hidden motion-safe:animate-ping absolute bg-red-600 justify-center rounded-full px-1 mr-4 text-white text-xs">
                  !
                </p>
              )}
              {/* <img src={bell} alt="bell" className="md:hidden w-5" /> */}
              <div className=" dark:stroke-white stroke-black md:hidden w-5">
                <svg
                  width="20"
                  height="25"
                  viewBox="0 0 20 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0277 2.3999C6.31385 2.3999 3.29562 5.4136 3.29562 9.12189V12.3597C3.29562 13.0431 3.00389 14.085 2.65607 14.6675L1.36574 16.8074C0.569107 18.1294 1.1189 19.597 2.57752 20.0899C7.41343 21.7032 12.6308 21.7032 17.4667 20.0899C18.8244 19.6418 19.4191 18.0397 18.6785 16.8074L17.3882 14.6675C17.0516 14.085 16.7599 13.0431 16.7599 12.3597V9.12189C16.7599 5.4248 13.7304 2.3999 10.0277 2.3999Z"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11.8 3.3C11.4984 3.17143 11.187 3.07143 10.866 3.01429C9.9319 2.84286 9.03677 2.94286 8.20001 3.3C8.48217 2.24286 9.18272 1.5 10 1.5C10.8173 1.5 11.5178 2.24286 11.8 3.3Z"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.6 20.3999C13.6 22.3799 11.98 23.9999 9.99999 23.9999C9.01599 23.9999 8.10399 23.5919 7.45599 22.9439C6.80799 22.2959 6.39999 21.3839 6.39999 20.3999"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                </svg>
              </div>
            </button>
          </div>
          <div className="text-gray-700 dark:text-gray-200 ">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
