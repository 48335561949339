export const formState = (state, action) => {
  switch (action.type) {
    case "INPUTVALUES": {
      return { ...state, [action.data.name]: action.data.value };
    }
    case "ADDBENEFICIARIES": {
      return { ...state, [action.data.name]: action.data.value };
    }
    case "CLEAR_FORM_DATA": {
      return {};
    }

    default:
      return state;
  }
};
