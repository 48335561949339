import image404 from "../icons/404.jpg";

import { AppDataContext } from "../providers/appData";

import { useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";
import Layout from "../Layout";

function Page404() {
  const { appData, dispatch } = useContext(AppDataContext);
  const history = useHistory();
  const back = () => {
    history.push("/");
  };
  return (
    <Layout>
      <div className="text-gray-700  flex flex-col  justify-between  items-center  bg-white h-[80vh] ">
        <img src={image404} alt="" className="h-[50vh]" />
        <p className=" font-medium text-sm md:text-xl">
          This page is not available
        </p>
        <button
          onClick={back}
          className="py-2 px-4 md:py-4 md:px-8 bg-primary-orange hover:bg-primary-orange focus:ring-primary-orange focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base  md:text-lg font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg mt-6"
        >
          Take me home
        </button>
      </div>
    </Layout>
  );
}

export default Page404;
