import React, { useContext, useEffect, useState } from "react";
import eye from "../icons/eye.svg";
import eyeclosed from "../icons/eye-blocked.svg";
import { useHistory } from "react-router-dom";
import { AppDataContext } from "../providers/appData";
import { UserContext } from "../providers/userData";
import { FormContext } from "../providers/formValues";
import loadingSmall from "../icons/loadingSmall.svg";
import phonebook from "../icons/phonebook.png";
import ContactModal from "../components/ContactModal";

import Layout from "../Layout";
import PaymentType from "../components/paymentType";

function TranserToUser() {
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [sending, setSending] = useState(false);
  const [focused, setFocused] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };

  document.title = "Transfer Funds-" + appData.business.name;

  // Function  to enroll tip! check for device capability before enrollment
  const getPhoneNumber = () => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "getPhoneNumber" })
      );
  };

  const history = useHistory();
  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  useEffect(() => {
    formDispatch({
      type: "CLEAR_FORM_DATA",
    });
    if (!formData.ref || typeof formData.ref === "undefined") {
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
    document.getElementById("receiver").addEventListener("input", function () {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "receiver",
          value: this.value.replaceAll(" ", ""),
        },
      });
    });

    window.scrollTo(0, 0);
  }, []);

  const verifyReceiver = (e) => {
    e.preventDefault();
    setSending(true);
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "receiverName", value: "" },
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("receiver", String(formData.receiver));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "verify_user", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);

        setSending(false);
        if (data.status === "success") {
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "receiverName", value: data.data.name },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        setSending(false);
      });
  };

  const handleSubmit = (e) => {
    e.persist();
    e.preventDefault();

    setSending(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append("receiver", String(formData.receiver));
    urlencoded.append("description", String(formData.description));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("password", String(formData.password));
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "wallet_transfer", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setSending(false);

        if (data.status === "success") {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });

          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          setSending(false);
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
          setSending(false);
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setSending(false);
      });
  };

  return (
    <Layout PageName={"Transfer to User"}>
      <ContactModal />
      <div className="flex flex-col mt-7">
        <form onSubmit={verifyReceiver}>
          <div className="w-full">
            <div className=" relative ">
              <label>
                <div className="flex justify-between items-center">
                  <div className="mt-4 font-medium text-primary-black text-sm md:text-base">
                    Reciever's Phone Number/ Email
                  </div>
                  <div className="mt-4 font-medium text-primary-gray text-xs md:text-base">
                    Balance:{`₦ ${user.data.wallet_balance}`}
                  </div>
                </div>

                <div className="flex relative mt-2.5">
                  <input
                    type="text"
                    name="receiver"
                    className=" rounded-l-lg flex-1  appearance-none border border-slate-300 w-full py-2 px-4  md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   "
                    placeholder="xxxx"
                    value={formData.receiver}
                    inputMode="text"
                    id="receiver"
                    // pattern="^[0-9]{11,11}$"
                    autoComplete="off"
                    required
                    onChange={(e) => {
                      formOnChange(e);
                    }}
                    // style={{
                    //   marginRight:
                    //     localStorage.getItem("isMobileApp") && "44px",
                    // }}
                  />
                  {localStorage.getItem("isMobileApp") && (
                    <img
                      src={phonebook}
                      width={42}
                      alt=""
                      className=" right-0 bottom-12     p-1 border border-slate-300 dark:bg-white "
                      style={{ bottom: 0 }}
                      onClick={(e) => {
                        e.preventDefault();
                        getPhoneNumber();
                      }}
                    />
                  )}
                  <span className="rounded-r-md inline-flex bg-primary-orange items-center px-3 border-t text-white border-r border-b  border-slate-300 shadow-sm text-sm">
                    <button className="">
                      {sending ? (
                        <div className="flex items-center justify-center">
                          <img
                            src={loadingSmall}
                            alt="loading ..."
                            className="w-7 h-7 "
                          />
                        </div>
                      ) : formData.receiverName ? (
                        `Verified`
                      ) : (
                        `Verify`
                      )}
                    </button>
                  </span>
                </div>
                <p className="text-xs text-right">{formData.receiverName}</p>
              </label>
            </div>
          </div>
        </form>
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <div className=" relative ">
              <label>
                <p className="mt-4 font-medium text-primary-black text-sm md:text-base">
                  Amount to Transfer
                </p>
                <input
                  type="text"
                  className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4  md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-3.5"
                  placeholder="Amount min ₦50"
                  name="amount"
                  inputMode="numeric"
                  autoComplete="off"
                  pattern="^([5-9]\d|[1-9]\d{1,})$"
                  required
                  value={formData.amount}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                />
              </label>
            </div>
          </div>
          <div className="w-full">
            <div className=" relative ">
              <label>
                <p className="mt-4 font-medium text-primary-black text-sm md:text-base">
                  Description
                </p>
                <input
                  type="text"
                  className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4  md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-3.5"
                  name="description"
                  autoComplete="off"
                  value={formData.description}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  placeholder="Max 50 characters"
                />
              </label>
            </div>
          </div>
          <PaymentType
            sending={sending}
            setFocused={setFocused}
            focused={focused}
            handleFocus={handleFocus}
            walletOnly={true}
            buttonTag="Transfer"
          />
        </form>
      </div>
    </Layout>
  );
}

export default TranserToUser;
