import React, { useContext } from "react";
import copy from "../icons/copy.svg";
import { useState } from "react";
import { UserContext } from "../providers/userData";
import { AppDataContext } from "../providers/appData";
import { toast } from "react-toastify";
import Layout from "../Layout";
import loadingSmall from "../icons/loadingSmall.svg";
import { Link, useHistory } from "react-router-dom";

function MonifyFund() {
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const [sending, setSending] = useState(false);
  const history = useHistory();

  document.title = "Fund Wallet with Bank-" + appData.business.name;

  const Copy = (text) => {
    navigator.clipboard.writeText(text).then(() => toast("Copied"));
  };

  const getAdditionalBanks = () => {
    setSending(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(localStorage.getItem("apiURL") + "all_monnify_banks", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        refreshUser();
      })
      .catch((error) => {
        setSending(false);
      });
  };
  const refreshUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      localStorage
        .getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success") {
          userDispatch({ type: "UPDATE_USER", action: data });
          setSending(false);
          toast("Monnify Banks Updated!");
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        }
      });
  };

  return (
    <Layout PageName={"Fund via Monnify"}>
      <div className="divide-y-2 divide-primary-gray">
        <div className="flex flex-col mt-8 space-y-3.5 pb-9">
          <div className="flex justify-between ">
            {" "}
            <p className="font-medium text-sm text-gray-700 dark:text-gray-200 md:text-base">
              Status:
            </p>
            <p className="font-semibold text-sm text-red-500 md:text-base ">
              {appData.master_settings.fund_status_bank * 1 === 0 ? (
                <p className="font-semibold text-sm text-green-500 ">Working</p>
              ) : (
                <p className="font-semibold text-sm text-red-500 ">Downtime</p>
              )}
            </p>
          </div>
          <div className="flex justify-between ">
            {" "}
            <p className="font-medium text-sm text-gray-700 dark:text-gray-200 md:text-base">
              Delivery time: :
            </p>
            <p className="font-semibold text-sm text-gray-700 dark:text-gray-200 md:text-base">
              1 - 10 Minutes
            </p>
          </div>

          <div className="flex justify-between ">
            {" "}
            <p className="font-medium text-sm text-gray-700 dark:text-gray-200 md:text-base">
              Transaction Fee:
            </p>
            <p className="font-semibold text-sm text-gray-700 dark:text-gray-200 md:text-base">
              ₦53.75
            </p>
          </div>

          {(!user.data.moniepoint_account_number ||
            !user.data.sterling_account_number ||
            !user.data.gt_account_number ||
            !user.data.fidelity_account_number) && (
            <label>
              <button
                className="px-2 py-2 bg-primary-orange text-white font-medium text-xs   uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                style={{
                  backgroundColor: appData.business.primary_color,
                }}
                onClick={getAdditionalBanks}
              >
                {sending ? (
                  <img
                    src={loadingSmall}
                    alt="loading ..."
                    className="md:flex-2 w-7 h-7"
                  />
                ) : (
                  <p className="mb-4 lg:"> Get additional Bank Accounts</p>
                )}
              </button>
            </label>
          )}
        </div>
        <div className="pt-1 "></div>
      </div>
      <p className="text-gray-700 dark:text-gray-200 text-xs my-4 md:text-base">
        Transfer into any of your dedicated bank account for Monnify wallet
        funding.
      </p>
      {appData.master_settings.fund_status_bank * 1 === 0 && (
        <div className="flex flex-wrap gap-2.5">
          {user.data.gt_account_number && (
            <div className=" rounded overflow-hidden shadow shadow-lg h-auto  bg-primary-orange text-white  p-2  ">
              <div className="flex flex-col space-y-3">
                <div className="  items-center justify-start md:space-x-2 ">
                  <p className=" text-white text-sm">Bank: GTBank </p>
                </div>
                <div className="flex  items-center justify-start space-x-2 ">
                  <p className=" text-white text-sm">
                    Account Number: {user.data.gt_account_number}
                  </p>
                  <div className="flex gap-1 ">
                    <p className="text-xx  text-white">copy</p>
                    <img
                      src={copy}
                      alt="copy"
                      className=""
                      onClick={() => Copy(user.data.gt_account_number)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-start">
                  <p className=" text-white text-sm">
                    Account Name: DN-{appData.business.name}{" "}
                    {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                  </p>
                </div>
              </div>
            </div>
          )}

          {user.data.moniepoint_account_number && (
            <div className=" rounded overflow-hidden shadow-lg h-auto  bg-primary-orange text-white  p-2  ">
              <div className="flex flex-col space-y-3">
                <div className="  items-center justify-start md:space-x-2 ">
                  <p className=" text-white text-sm"> Bank: Moniepoint Bank </p>
                </div>
                <div className="flex  items-center justify-start space-x-2 ">
                  <p className=" text-white text-sm">
                    Account Number: {user.data.moniepoint_account_number}
                  </p>
                  <div className="flex gap-1 ">
                    <p className="text-xx  text-white">copy</p>
                    <img
                      src={copy}
                      alt="copy"
                      className=""
                      onClick={() => Copy(user.data.moniepoint_account_number)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-start">
                  <p className=" text-white text-sm">
                    Account Name: DN-{appData.business.name}{" "}
                    {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                  </p>
                </div>
              </div>
            </div>
          )}
          {user.data.sterling_account_number && (
            <div className=" rounded overflow-hidden shadow-lg h-auto  bg-primary-orange text-white  p-2  ">
              <div className="flex flex-col space-y-3">
                <div className="  items-center justify-start md:space-x-2 ">
                  <p className=" text-white text-sm">Bank: Sterling Bank </p>
                </div>
                <div className="flex  items-center justify-start space-x-2 ">
                  <p className=" text-white text-sm">
                    Account Number: {user.data.sterling_account_number}
                  </p>
                  <div className="flex gap-1 ">
                    <p className="text-xx  text-white">copy</p>
                    <img
                      src={copy}
                      alt="copy"
                      className=""
                      onClick={() => Copy(user.data.sterling_account_number)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-start">
                  <p className=" text-white text-sm">
                    Account Name: DN-{appData.business.name}{" "}
                    {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                  </p>
                </div>
              </div>
            </div>
          )}

          {user.data.fidelity_account_number && (
            <div className=" rounded overflow-hidden shadow-lg h-auto  bg-primary-orange text-white  p-2  ">
              <div className="flex flex-col space-y-3">
                <div className="  items-center justify-start md:space-x-2 ">
                  <p className=" text-white text-sm">Bank: Fidelity Bank </p>
                </div>
                <div className="flex  items-center justify-start space-x-2 ">
                  <p className=" text-white text-sm">
                    Account Number: {user.data.fidelity_account_number}
                  </p>
                  <div className="flex gap-1 ">
                    <p className="text-xx  text-white">copy</p>
                    <img
                      src={copy}
                      alt="copy"
                      className=""
                      onClick={() => Copy(user.data.fidelity_account_number)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-start">
                  <p className=" text-white text-sm">
                    Account Name: DN-{appData.business.name}{" "}
                    {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                  </p>
                </div>
              </div>
            </div>
          )}

          {user.data.monnify_account_number && (
            <div className=" rounded overflow-hidden shadow-lg h-auto  bg-primary-orange text-white  p-2  ">
              <div className="flex flex-col space-y-3">
                <div className="  items-center justify-start md:space-x-2 ">
                  <p className=" text-white text-sm"> Bank: Wema Bank </p>
                </div>
                <div className="flex  items-center justify-start space-x-2 ">
                  <p className=" text-white text-sm">
                    Account Number: {user.data.monnify_account_number}
                  </p>
                  <div className="flex gap-1 ">
                    <p className="text-xx  text-white">copy</p>
                    <img
                      src={copy}
                      alt="copy"
                      className=""
                      onClick={() => Copy(user.data.monnify_account_number)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-start">
                  <p className=" text-white text-sm">
                    Account Name: DN-{appData.business.name}{" "}
                    {`${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}

export default MonifyFund;
