import whatsapp from "../icons/whatsapp.svg";
import sms from "../icons/sms.svg";
import location from "../icons/location.svg";
import { useContext } from "react";
import { AppDataContext } from "../providers/appData";
import { getOS } from "../helper/getOs";
import Layout from "../Layout";

function Contact() {
  const { appData } = useContext(AppDataContext);

  document.title = "Contact Us" + appData.business.name;

  return (
    <Layout PageName={"Contact Us"}>
      <div className="flex flex-col justify-center align-middle self-center mt-9">
        <div className="flex flex-col align-middle justify-center">
          <h2 className="text-center font-medium text-sm text-primary-gray md:text-lg">
            Location
          </h2>
          <p className=" text-xs self-center font-semibold mt-2 text-gray-700 dark:text-gray-200   md:text-base">
            {appData.business.address}
          </p>
        </div>
        <div className="flex flex-col mt-5">
          <h2 className="text-sm text-center font-medium text-primary-gray  md:text-lg">
            Email address
          </h2>
          <p className="text-xs self-center font-semibold mt-2 text-gray-700 dark:text-gray-200  ">
            <a href={"Mailto:" + appData.settings.contact_email_address}>
              {appData.settings.contact_email_address}
            </a>
          </p>
        </div>
        <div className="flex flex-col mt-5">
          <h2 className="text-sm text-primary-gray text-center font-medium  md:text-lg">
            Phone number
          </h2>
          <div className="flex flex-col self-center font-semibold mt-2 gap-1 text-gray-700 dark:text-gray-200  ">
            <p className="text-xs md:text-lg">
              {" "}
              <a href={"tel:" + appData.settings.contact_call_number2}>
                {" "}
                {appData.settings.contact_call_number2}
              </a>
            </p>
            <p className="text-xs md:text-lg">
              <a href={"tel:" + appData.settings.contact_call_number1}>
                {" "}
                {appData.settings.contact_call_number1}
              </a>
            </p>
          </div>
          <div className=" mt-8">
            <h3 className="flex flex-col text-primary-gray text-center font-medium text-sm md:text-lg ">
              Contact Links
            </h3>
            <div className="flex mt-2 space-x-4 justify-center item-center">
              <button className="flex items-center  justify-center h-11 w-11 md:h-20 md:w-20 rounded-md bg-green-500 text-white">
                <a
                  target="_"
                  href={
                    getOS() === "WEB"
                      ? "https://web.whatsapp.com/send?phone=234" +
                        appData.settings.contact_whatsapp_number +
                        "&text=Hello!"
                      : "https://api.whatsapp.com/send?phone=234" +
                        appData.settings.contact_whatsapp_number +
                        "&text=Hello!"
                  }
                >
                  <img src={whatsapp} alt="whatsapp" className="md:h-16" />
                </a>
              </button>
              <button className="flex items-center mx-auto justify-center h-11 w-11 md:h-20 md:w-20 rounded-md bg-red-400 text-white">
                <a href={"Mailto:" + appData.settings.contact_email_address}>
                  <img src={sms} alt="E-mail" className="md:h-16" />
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
