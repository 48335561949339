import React, { useContext, useState, useEffect } from "react";
import bin from "../icons/bin.svg";
import bank from "../icons/bank.svg";
import wallet from "../icons/empty-wallet.svg";
import right from "../icons/right.svg";
import { ReactComponent as RightAngle } from "../icons/rightarrowdark.svg";
import loadingSmall from "../icons/loadingSmall.svg";
import { useHistory, Link } from "react-router-dom";
import { UserContext } from "../providers/userData";
import { AppDataContext } from "../providers/appData";
import { FormContext } from "../providers/formValues";
import { getOS } from "../helper/getOs";
import { toast } from "react-toastify";
import Alert from "../components/Alert";
import copy from "../icons/copy.svg";
import Layout from "../Layout";
function AutoAgentFund() {
  const { user, userDispatch } = useContext(UserContext);
  const { appData, dispatch } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [sending, setSending] = useState(false);
  const [sendingDel, setSendingDel] = useState({ deleting: false, index: 0 });
  const [showform, setShowform] = useState(false);
  const [focused, setFocused] = useState(false);

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const toggleDaytime = () => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "afternoon", value: !formData.afternoon },
    });
  };

  const handleFocus = (e) => {
    setFocused(true);
  };

  document.title = "Fund Wallet with Autoagent-" + appData.business.name;

  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }

    document.getElementById("hour").addEventListener("input", function () {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "hour",
          value:
            this.value.replace(/\D/g, "") <= 12
              ? this.value.replace(/\D/g, "") * 1
              : 12,
        },
      });
    });

    document.getElementById("minute").addEventListener("input", function () {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "minute",
          value:
            this.value.replace(/\D/g, "") <= 59
              ? this.value.replace(/\D/g, "") * 1
              : 0,
        },
      });
    });

    if (!formData.errorCount)
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "errorCount", value: 0 },
      });
  }, []);

  const onValidSubmit = (e) => {
    e.persist();
    e.preventDefault();
    setSending(true);
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", String(formData.autoAgentId));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("date", String(formData.date));
    urlencoded.append(
      "time",
      formData.hour +
        ":" +
        formData.minute +
        (formData.afternoon ? ":00 PM" : ":00 AM")
    );
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };

    fetch(localStorage.getItem("apiURL") + "selffix_autoagent", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setSending(false);
        setShowform(false);
        if (data.status === "success") {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: {
                isOpen: true,
                message: data.message,
                show: false,
                stayOnClose: false,
              },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "errorCount", value: formData.errorCount + 1 },
          });
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: {
                isOpen: true,
                message: errorString,
                type: "error",
                hideHomeBtn: formData.errorCount < 2 ? true : false,
              },
            },
          });

          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "errorCount", value: formData.errorCount + 1 },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: {
                isOpen: true,
                message: data.message,
                type: "error",
                hideHomeBtn: formData.errorCount < 2 ? true : false,
              },
            },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: { name: "errorCount", value: formData.errorCount + 1 },
        });
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
              hideHomeBtn: formData.errorCount < 2 ? true : false,
            },
          },
        });
        setSending(false);
      });
  };

  const history = useHistory();

  return (
    <Layout PageName={"Self-Fix Auto Agent Funding"}>
      <div className="px-0 py-8">
        <div className=" font-medium text-sm   ">
          <div>
            <div className="w-full mt-3 border border-blue-300 rounded-lg shadow dark:border md:sm:max-w-md xl:p-0 dark:bg-gray-900 dark:border-gray-700">
              <div className="p-3 space-y-4 md:space-y-6 sm:p-8">
                <div className="mb-2">
                  <p
                    className=" text-left mb-0"
                    style={{ color: appData.business.primary_color }}
                  >
                    <b className="text-uppercase">Self-Fix AutoAgent Funding</b>
                  </p>
                  <hr
                    style={{ borderColor: appData.business.primary_color }}
                    className="mt-4"
                  />
                </div>

                <form
                  className="space-y-4 md:space-y-6 "
                  autoComplete="off"
                  onSubmit={onValidSubmit}
                >
                  {formData.error && (
                    <Alert color="danger">{formData.error}</Alert>
                  )}

                  <label
                    for="email"
                    className="block mb-0 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Account Transferred From
                  </label>
                  <select
                    value={formData.autoAgentId}
                    name="autoAgentId"
                    required
                    onChange={formOnChange}
                    className=" text-sm bg-white border border-gray-300 text-gray-900 sm:text-sm 
              rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
              dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">--Select Registered Bank---</option>
                    {user.data.auto_agent_accounts.map((bank, index) => {
                      return (
                        <option key={bank.id} value={bank.id}>
                          {appData.banks[
                            appData.banks.findIndex(
                              (iteratedBank) =>
                                iteratedBank.id * 1 === bank.bank_id * 1
                            )
                          ].name +
                            " " +
                            bank.account_number +
                            "(" +
                            bank.account_name +
                            ")"}
                        </option>
                      );
                    })}
                  </select>
                  <label className="block  text-primary-black text-sm font-semibold  mt-3">
                    Can't find your account?
                    <Link to="/AutoAgent">
                      {" "}
                      <label
                        className=" p-1 text-white text-sm small rounded-full"
                        style={{
                          backgroundColor: appData.business.primary_color,
                        }}
                      >
                        Register it here
                      </label>
                    </Link>
                  </label>
                  <div className="">
                    <label
                      for="amount"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Amount Sent
                    </label>
                    <div className="mb-3 xl:w-96">
                      <input
                        type="number"
                        data-type="currency"
                        step=".01"
                        placeholder="Amount Sent"
                        name="amount"
                        value={formData.amount}
                        className="bg-white border border-gray-300 text-gray-900 sm:text-sm 
                  rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
                  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="off"
                        focused={focused.toString()}
                        required
                        onBlur={handleFocus}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                        options={{ minDate: "2017-01-01" }}
                      />
                      <span>Enter valid currency</span>
                    </div>
                  </div>

                  <div className="">
                    <label
                      for="amount"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Date as seen on transaction receipt
                    </label>
                    <div className="mb-3 xl:w-96">
                      <input
                        type="date"
                        placeholder="xx/xx/xxxx"
                        name="date"
                        value={formData.date}
                        className="bg-white border border-gray-300 text-gray-900 sm:text-sm 
                  rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
                  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="off"
                        focused={focused.toString()}
                        required
                        onBlur={handleFocus}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                      />
                      <span>Enter valid date</span>
                    </div>
                  </div>

                  <div className="">
                    <label
                      for="time"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Time as seen on transaction receipt
                    </label>

                    <div className=" grid grid-cols-8   gap-1    mt-1  md:mt-4 ">
                      <input
                        type="text"
                        inputMode="numeric"
                        pattern="^[0-9]{1,6}$"
                        name="hour"
                        id="hour"
                        value={formData.hour}
                        className="text-center text-2xl col-span-2 bg-white border border-gray-300 text-gray-900 
                  rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
                  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="off"
                        focused={focused.toString()}
                        required
                        onBlur={handleFocus}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                      />
                      <div className="mx-auto my-auto ">
                        <label className="text-2xl">:</label>
                      </div>
                      <input
                        type="text"
                        inputMode="numeric"
                        pattern="^[0-9]{1,6}$"
                        name="minute"
                        id="minute"
                        value={formData.minute}
                        className="text-center text-2xl col-span-2 bg-white border border-gray-300 text-gray-900  
                  rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
                  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        autoComplete="off"
                        focused={focused.toString()}
                        required
                        onBlur={handleFocus}
                        onChange={(e) => {
                          formOnChange(e);
                        }}
                      />

                      <div
                        className="border border-gray-800 rounded-lg ml-2 mr-2 col-span-2"
                        onClick={toggleDaytime}
                      >
                        <p
                          className={
                            !formData.afternoon
                              ? "p-2 bg-blue-300 text-center text-blue-800 rounded-t-lg"
                              : "p-2  text-gray-400 text-center   rounded-b-lg"
                          }
                        >
                          AM
                        </p>
                        <p
                          className={
                            formData.afternoon
                              ? "p-2 bg-blue-300 text-center text-blue-800 rounded-b-lg"
                              : "p-2  text-gray-400 text-center   rounded-b-lg"
                          }
                        >
                          PM
                        </p>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    style={{
                      backgroundColor: appData.business.primary_color,
                    }}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    disabled={sending}
                    className="inline-block mb-3 xl:w-96  px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    {sending ? (
                      <div className="flex items-center justify-center">
                        <img
                          src={loadingSmall}
                          alt="loading ..."
                          className="w-7 h-7 "
                        />
                      </div>
                    ) : (
                      " Resolve Funding"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AutoAgentFund;
