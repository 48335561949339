import userEvent from "@testing-library/user-event";
import React, { useContext, useEffect, useState } from "react";
import { FormContext } from "../providers/formValues";
import { UserContext } from "../providers/userData";
import { useHistory, Link } from "react-router-dom";
import CurrencyFormat from "../helper/CurrencyFormat";
import loadingSmall from "../icons/loadingSmall.svg";
import eye from "../icons/eye.svg";
import eyeclosed from "../icons/eye-blocked.svg";
import fingerprint from "../icons/fingerprint.svg";
import faceId from "../icons/faceId.png";

function PaymentType(props) {
  const history = useHistory();

  const { formData, formDispatch } = useContext(FormContext);
  const { user, userDispatch } = useContext(UserContext);
  const [showPassword, setshowPassword] = useState(true);

  // Function  to enroll tip! check for device capability before enrollment
  const isBiometricSupported = () => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "isBiometricSupported" })
      );
  };

  //function to check for enrollment
  const isBiometricEnrolled = () => {
    const enrollmentStatus = window.localStorage.getItem("biometricEnrolled");
    if (enrollmentStatus == null) {
      return false;
    }
    return true;
  };

  // Function  for authentication
  const getBiometricAuth = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ command: "retrivePassword" })
    );
  };

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  const paymentwallet = (toggle) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "atmPayment", value: false },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "walletPayment", value: toggle },
    });
  };
  const paymentatm = (toggle) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "atmPayment", value: toggle },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "walletPayment", value: false },
    });
  };

  useEffect(() => {
    isBiometricSupported();
    user.data ? paymentwallet(true) : paymentatm(true);
  }, []);

  return (
    <>
      {!props.walletOnly && (
        <>
          <h3 className="mt-2 font-medium text-gray-700 dark:text-gray-200 text-sm md:text-base mb-0">
            Payment Method
          </h3>
          <div className="flex gap-4 item-center " style={{ marginTop: 10 }}>
            <button
              type="button"
              className={
                "py-2 px-4 md:py-4 md:px-8 flex justify-center items-center  hover:text-white   w-full transition ease-in duration-200 border border-slate-300 text-center text-base md:text-lg font-medium shadow-md   rounded-lg " +
                (formData.walletPayment
                  ? "bg-primary-orange text-white"
                  : "bg-white text-gray-700 ")
              }
              onClick={() => paymentwallet(true)}
            >
              Wallet
            </button>
            <button
              type="button"
              className={
                "py-2 px-4 md:py-4 md:px-8 flex justify-center items-center  hover:text-white   w-full transition ease-in duration-200 border border-slate-300 text-center text-base md:text-lg font-medium shadow-md   rounded-lg " +
                (formData.atmPayment
                  ? "bg-primary-orange text-white"
                  : "bg-white text-gray-700")
              }
              onClick={() => paymentatm(true)}
            >
              ATM
            </button>
          </div>
        </>
      )}
      <div className="w-full">
        {formData.atmPayment || !user.data ? (
          ""
        ) : (
          <div className=" relative ">
            <label>
              <p className="mt-2 font-medium  text-sm md:text-base">Password</p>
              <div style={{ marginRight: isBiometricEnrolled() && "44px" }}>
                <input
                  type={showPassword ? "password" : "text"}
                  className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  focused={props.focused.toString()}
                  required
                  autoComplete="off"
                  name="password"
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                  value={formData.password}
                />
                {showPassword ? (
                  <img
                    src={eye}
                    alt=""
                    className="absolute right-5 bottom-12 h-5 w-5 md:h-8 md:w-8"
                    style={{ marginRight: isBiometricEnrolled() && "40px" }}
                    onClick={() => {
                      setshowPassword((prevshowPassword) => !prevshowPassword);
                    }}
                  />
                ) : (
                  <img
                    src={eyeclosed}
                    alt=""
                    style={{ marginRight: isBiometricEnrolled() && "40x" }}
                    className="absolute right-5 bottom-12 h-5 w-5 md:h-8 md:w-8"
                    onClick={() => {
                      setshowPassword(!showPassword);
                    }}
                  />
                )}
              </div>
              {isBiometricEnrolled() && (
                <img
                  src={
                    formData.biometricType === "Fingerprint"
                      ? fingerprint
                      : faceId
                  }
                  width={42}
                  alt=""
                  className="absolute right-0 bottom-12  rounded-lg  p-1 border border-slate-300 dark:bg-white "
                  style={{ right: -2, bottom: 36 }}
                  onClick={(e) => {
                    e.preventDefault();
                    getBiometricAuth();
                  }}
                />
              )}

              <span className="absolute">Please enter your password </span>
              <label className="block  text-primary-black text-sm font-semibold  text-right mt-4">
                <Link to="/forgotPassword">Forgot your password?</Link>
              </label>
            </label>
          </div>
        )}
      </div>

      {/* {formData.atmPayment && !user.data && (
        <div className="w-full">
          <div className=" relative ">
            <label>
              <p className="mt-4 font-medium text-primary-black text-sm md:text-base">
                Email address
              </p>

              <input
                type="email"
                className=" rounded-lg  flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-3.5"
                placeholder="Your email address"
                focused={props.focused.toString()}
                required
                name="email"
                onBlur={props.handleFocus}
                value={formData.email}
                onChange={(e) => {
                  formOnChange(e);
                }}
              />

              <span>Please enter a valid email address</span>
            </label>
          </div>
        </div>
      )} */}
      {!user.data ? (
        <button
          onClick={() => {
            history.push("/signin");
          }}
          className="py-2 px-4 md:py-4 md:px-8 bg-primary-orange hover:bg-primary-orange focus:ring-primary-orange focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base  md:text-lg font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg mt-3"
        >
          Sign In to Pay
        </button>
      ) : (
        <div>
          {formData.network === "AIRTEL" && (
            <p className="text-red-500 text-sm">
              Warning! Data will not be received if you have an outstanding
              airtime/data loan
            </p>
          )}
          <span className="block w-full rounded-md shadow-sm">
            <button
              className="py-2 px-4 md:py-4 md:px-8 bg-primary-orange hover:bg-primary-orange focus:ring-primary-orange focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base  md:text-lg font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg mt-2"
              type="submit"
              id="payButton"
              onClick={(e) => {
                if (props.sending) {
                  e.preventDefault();
                  return;
                }
              }}
            >
              {props.sending ? (
                <div className="flex items-center justify-center">
                  <img
                    src={loadingSmall}
                    alt="loading ..."
                    className="w-7 h-7 "
                  />
                </div>
              ) : formData.atmPrice || formData.walletPrice ? (
                "Pay ₦" +
                CurrencyFormat(
                  formData.commission
                    ? formData.amount
                      ? formData.atmPayment
                        ? formData.calcAmount
                        : formData.amount -
                          (formData.amount * formData.commission) / 100
                      : 0
                    : formData.atmPayment
                    ? formData.atmPrice
                    : formData.walletPrice
                )
              ) : props.buttonTag ? (
                props.buttonTag
              ) : (
                "Pay"
              )}
            </button>
          </span>
        </div>
      )}
    </>
  );
}

export default PaymentType;
