import React, { useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Connecting from "./icons/connecting.gif";

import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";

import { AppDataContext } from "./providers/appData";
import { UserContext } from "./providers/userData";
import { FormContext } from "./providers/formValues";
import TranserToUser from "./pages/transerToUser";
import SignOut from "./pages/signOut";
import Page404 from "./pages/404";
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from "./pages/resetPassword";
import AirtimePurchase from "./pages/airtimePurchase";
import Balance from "./pages/balance";
import CablePurchase from "./pages/cablePurchase";
import Contact from "./pages/contact";
import DataPurchase from "./pages/dataPurchase";
import Faq from "./pages/faq";
import Home from "./pages/home";
import LoginPage from "./pages/loginPage";
import Profile from "./pages/profile";
import SignUp from "./pages/signUp";
import Transactions from "./pages/transactions";
import Beneficiaries from "./pages/beneficiaries";
import AutoAgentFund from "./pages/autoAgentFund";
import MonifyFund from "./pages/monifyFund";
import Selffix from "./pages/selffix";
import AtmFund from "./pages/atmFund";
import ElectricityPayments from "./pages/electricityPayments";
import Prices from "./pages/prices";
import Kyc from "./pages/kyc";
import { getOS } from "./helper/getOs";
import Kyc404 from "./pages/kyc404";
import AccountDeleted from "./pages/accountDeleted";
import VerifyPhone from "./pages/verifyPhone";
import SelfFixAutoagent from "./pages/selffixAutoagent";

function App() {
  const { user, userDispatch } = useContext(UserContext);
  const { dispatch, appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);

  const processMessage = (message) => {
    if (!JSON.stringify(message.data).includes("command")) return;
    const msg = JSON.parse(message.data);
    if (msg.command === "ping") {
      localStorage.setItem("isMobileApp", true);
    } else if (msg.command === "biometricType") {
      return formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "biometricType",
          value: msg.data,
        },
      });
    }
    if (msg.command === "enrolledBiometric") {
      localStorage.setItem("biometricEnrolled", formData.biometricType);
      return formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "biometricEnrolled",
          value: true,
        },
      });
    } else if (msg.command === "password") {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "password",
          value: msg.data,
        },
      });
      window.document.getElementById("payButton").click();
    } else if (msg.command === "contact") {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "contacts",
          value: msg.data,
        },
      });
    }
  };

  const fetchAppData = () => {
    const requestOptions = {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        pragma: "no-cache",
        "cache-control": "no-cache",
        Authorization: user ? `Bearer ${user.token}` : "",
      }),
    };

    var url = new URL(window.location.href);
    var device_token = url.searchParams.get("device_token");
    var dataURL = localStorage.getItem("apiURL") + "data";
    if (localStorage.isMobileApp && device_token)
      dataURL = dataURL + "?device_token=" + device_token;
    fetch(dataURL, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        document.documentElement.style.setProperty(
          "--primary-color",
          response.data.business.primary_color
        );
        document.documentElement.style.setProperty(
          "--secondary-color",
          response.data.business.secondary_color
        );
        dispatch({
          type: "STORE_APP_DATA",
          appData: {
            timestamp: new Date().getTime(),
            ...response.data,
          },
        });
      });
  };

  useEffect(() => {
    // Function receiving Data from React Native use both window and document event listener for iOS and Android to work
    window.addEventListener("message", (message) => {
      processMessage(message);
    });
    document.addEventListener("message", (message) => {
      processMessage(message);
    });
    //check if it is mobile App
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "ping" })
      );

    if (appData.settings) {
      const headTitle = document.querySelector("head");
      const setFavicon = document.createElement("link");
      setFavicon.setAttribute("rel", "shortcut icon");
      setFavicon.setAttribute("href", window.location.origin + `/logo.png`);
      headTitle.appendChild(setFavicon);

      document.documentElement.style.setProperty(
        "--primary-color",
        appData.business.primary_color
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        appData.business.secondary_color
      );

      if (user.data) {
        userDispatch({
          type: "UPDATE_SHOW_NOTIFICATION",
          action: true,
        });
      }
    } else fetchAppData();
  }, []);

  if (!appData.settings)
    return (
      <div>
        <img src={Connecting} alt="" className="mx-auto my-auto h-[50vh]" />
        <p className="text-center">Connecting to Server...</p>
      </div>
    );
  else {
    return (
      <div className="bg-white   dark:bg-gray-900">
        {localStorage.isMobileApp && getOS() === "IOS" && (
          <div
            style={{
              height: "45px",
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 999999,
            }}
            className="bg-white   dark:bg-gray-900"
          />
        )}
        <div
          style={{
            paddingTop:
              localStorage.isMobileApp && getOS() === "IOS" ? "45px" : "0px",
          }}
        >
          {user.data && user.data.status == 1 ? ( //added this line to prevent access to app when account has been deleted
            <Router>
              <Switch>
                <Route path="*">
                  <AccountDeleted />
                </Route>
              </Switch>
            </Router>
          ) : (
            <Router>
              <Switch>
                <Route path="/signin">
                  <LoginPage />
                </Route>
                <Route path="/signUp">
                  <SignUp />
                </Route>
                <Route path="/forgotPassword">
                  <ForgotPassword />
                </Route>
                <Route path="/resetPassword">
                  <ResetPassword />
                </Route>

                <Route path="/" exact render={() => <Home />} />
                <Route path="/index.html" exact render={() => <Home />} />
                <Route
                  path="/transactions"
                  render={() =>
                    user.data ? <Transactions /> : <Redirect to="/signin" />
                  }
                />

                <Route
                  path="/beneficiaries"
                  render={() =>
                    user.data ? <Beneficiaries /> : <Redirect to="/signin" />
                  }
                />

                <Route
                  path="/profile"
                  render={() =>
                    user.data ? <Profile /> : <Redirect to="/signin" />
                  }
                />
                <Route
                  path="/selffix"
                  render={() =>
                    user.data ? <Selffix /> : <Redirect to="/signin" />
                  }
                />
                <Route
                  path="/selffixAutoagent"
                  render={() =>
                    user.data ? <SelfFixAutoagent /> : <Redirect to="/signin" />
                  }
                />
                <Route
                  path="/kyc"
                  render={() =>
                    user.data ? <Kyc /> : <Redirect to="/signin" />
                  }
                />
                <Route
                  path="/verifyPhone"
                  render={() =>
                    user.data ? <VerifyPhone /> : <Redirect to="/signin" />
                  }
                />

                <Route path="/prices" render={() => <Prices />} />
                <Route path="/data" render={() => <DataPurchase />} />
                <Route path="/airtime" render={() => <AirtimePurchase />} />

                <Route path="/cable" render={() => <CablePurchase />} />

                <Route
                  path="/electricity"
                  render={() => <ElectricityPayments />}
                />

                <Route
                  path="/code"
                  render={() =>
                    user.data ? <Balance /> : <Redirect to="/signin" />
                  }
                />

                <Route path="/faq">
                  <Faq />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/signout">
                  <SignOut />
                </Route>
                <Route
                  path="/AutoAgent"
                  render={() =>
                    user.data ? <AutoAgentFund /> : <Redirect to="/signin" />
                  }
                />

                <Route
                  path="/monify"
                  render={() =>
                    user.data ? (
                      user.data.kyc_verified_at ? (
                        <MonifyFund />
                      ) : (
                        <Kyc404 />
                      )
                    ) : (
                      <Redirect to="/signin" />
                    )
                  }
                />

                <Route
                  path="/atm"
                  render={() =>
                    user.data ? <AtmFund /> : <Redirect to="/signin" />
                  }
                />

                <Route
                  path="/transferToUser"
                  render={() =>
                    user.data ? (
                      user.data.kyc_verified_at ? (
                        <TranserToUser />
                      ) : (
                        <Kyc404 />
                      )
                    ) : (
                      <Redirect to="/signin" />
                    )
                  }
                />

                <Route
                  path="*"
                  render={() =>
                    user.data ? <Page404 /> : <Redirect to="/signin" />
                  }
                />
              </Switch>
            </Router>
          )}
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(App);
