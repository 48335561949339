import React, { useContext, useEffect, useState } from "react";
import wrong from "../icons/wrong.svg";
import { AppDataContext } from "../providers/appData";
import { UserContext } from "../providers/userData";
import { useHistory } from "react-router-dom";
import Layout from "../Layout";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getOS } from "../helper/getOs";

function AccountDeleted() {
  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const [remainingDate, setRemainingDate] = useState(0);

  const history = useHistory();
  const logout = () => {
    localStorage.removeItem("biometricEnrolled");
    userDispatch({ type: "SIGNOUT" });
    history.push("/");
  };

  useEffect(() => {
    let startDate = new Date(user.data.deletion_date);
    let endDate = new Date();
    let difference = endDate.getTime() - startDate.getTime();
    let TotalDiffDays = Math.ceil(difference / (1000 * 3600 * 24));
    setRemainingDate(90 - TotalDiffDays);
  }, []);

  return (
    <Layout>
      <div className="text-gray-700  flex flex-col    items-center   light:bg-white h-[80vh] ">
        <img src={wrong} className="w-24 h-24 mt-16" alt="img" />
        <p className=" font-medium text-sm md:text-xl rounded bg-red-600  p-1 text-white text-center text-xs mt-10">
          Your account has been deleted!
          <p className=" font-medium text-sm md:text-xl rounded bg-red-600  p-1 text-white text-center text-xs  ">
            Data Retention Time: {remainingDate} days Remaining
          </p>
        </p>
        <p className="font-medium text-sm md:text-xl text-center mt-10 dark:text-white">
          Your account has been deleted and you no longer have access to it. You
          can choose to restore your account by contacting our customer care
          rep.
          <label className="cursor-pointer text-sm font-bold inline-block text-primary-orange ">
            <a
              target="_"
              href={
                getOS() === "WEB"
                  ? "https://web.whatsapp.com/send?phone=234" +
                    appData.settings.contact_whatsapp_number +
                    "&text=Hello! I'd like to restore my account"
                  : "https://api.whatsapp.com/send?phone=234" +
                    appData.settings.contact_whatsapp_number +
                    "&text=Hello! I'd like to restore my account"
              }
            >
              {" "}
              Contact customer care ➔
            </a>
          </label>
        </p>
        <p
          className="cursor-pointer text-sm font-bold inline-block text-primary-orange text-center mt-3"
          onClick={() => logout()}
        >
          Go to Menu
        </p>
      </div>
    </Layout>
  );
}

export default AccountDeleted;
