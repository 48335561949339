import { Link } from "react-router-dom";
import { AppDataContext } from "../providers/appData";
import { UserContext } from "../providers/userData";
import { useContext } from "react";
import React, { useRef, useState } from "react";
import { getOS } from "../helper/getOs";

function Header() {
  const ref = useRef();
  const toggleTooltip = () => ref.current.toggle();
  const { appData } = useContext(AppDataContext);
  const { user, userDispatch } = useContext(UserContext);
  return (
    <div className="w-full  fixed inset-x-0 top-0 hidden md:flex bg-white dark:bg-gray-900 items-center px-5 justify-between py-3.5 md:py-0 z-50 ">
      <Link to="/signin">
        <div className=" h-5 flex justify-center items-center p-8  ">
          <img
            src={window.location.origin + "/logo.png"}
            alt="logo"
            className=""
            style={{ height: 46 }}
          />
          <h1 className="font-medium text-lg md:text-2xl text-primary-orange text-center    ">
            {appData.business.name}
          </h1>
        </div>
      </Link>
      <div className="flex items-right space-x-4">
        <div className="">
          <div className="relative  md:mt-2">
            <button
              type="button"
              className="button flex"
              onClick={() => {
                userDispatch({
                  type: "UPDATE_SHOW_NOTIFICATION",
                  action: false,
                });
                userDispatch({
                  type: "UPDATE_USER_NOTIFICATION",
                  action: "",
                });
              }}
            >
              {user.notification && user.showNotification && (
                <p className="motion-safe:animate-ping absolute bg-red-600 justify-center rounded-full px-1 mr-4 text-white text-xs">
                  !
                </p>
              )}

              <div className=" dark:stroke-white stroke-black">
                <svg
                  width="20"
                  height="25"
                  viewBox="0 0 20 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0277 2.3999C6.31385 2.3999 3.29562 5.4136 3.29562 9.12189V12.3597C3.29562 13.0431 3.00389 14.085 2.65607 14.6675L1.36574 16.8074C0.569107 18.1294 1.1189 19.597 2.57752 20.0899C7.41343 21.7032 12.6308 21.7032 17.4667 20.0899C18.8244 19.6418 19.4191 18.0397 18.6785 16.8074L17.3882 14.6675C17.0516 14.085 16.7599 13.0431 16.7599 12.3597V9.12189C16.7599 5.4248 13.7304 2.3999 10.0277 2.3999Z"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11.8 3.3C11.4984 3.17143 11.187 3.07143 10.866 3.01429C9.9319 2.84286 9.03677 2.94286 8.20001 3.3C8.48217 2.24286 9.18272 1.5 10 1.5C10.8173 1.5 11.5178 2.24286 11.8 3.3Z"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.6 20.3999C13.6 22.3799 11.98 23.9999 9.99999 23.9999C9.01599 23.9999 8.10399 23.5919 7.45599 22.9439C6.80799 22.2959 6.39999 21.3839 6.39999 20.3999"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
        <div className="py-2.5 px-5 rounded-lg  md:flex justify-between items-center ml-10 hidden ">
          <div className="profile   ml-3.5">
            <p className=" text-xl font-medium text-primary-blue dark:text-white">
              {user.data &&
                `${user.data.firstname} ${user.data.othername} ${user.data.lastname}`}{" "}
              {user.data && user.data.kyc_verified_at && (
                <span class="inline-flex items-center justify-center w-6 h-6 me-2 text-sm font-semibold text-blue-800 bg-blue-100 rounded-full dark:bg-gray-700 dark:text-blue-400">
                  <svg
                    class="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
                    />
                    <path
                      fill="#fff"
                      d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
                    />
                  </svg>
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
