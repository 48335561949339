import React, { useContext } from "react";

import { withRouter } from "react-router-dom";

import { AppDataContext } from "../providers/appData";
import { FormContext } from "../providers/formValues";

const Terms = () => {
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);

  const toggle = () => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "termsModal", value: false },
    });
  };
  return (
    <>
      {formData.termsModal && (
        <div
          className={
            "justify-center items-center flex overflow-x-hidden  fixed inset-0 z-50 outline-none focus:outline-none w-full"
          }
        >
          <div
            className={
              "relative w-full my-6 mx-auto max-w-sm   rounded-2xl border-t-8  border-primary-orange "
            }
          >
            <div
              className="overflow-y-auto bg-white p-4 dark:bg-gray-900 max-w-2xl  overflow-hidden rounded-t-lg "
              style={{ maxHeight: "60vh" }}
            >
              <p className="font-semibold text-center text-lg">
                Terms and Conditions
              </p>
              <p>
                This Terms and Conditions (“Terms”) contained herein on this
                webpage is a legal agreement between you, as a prospective
                customer of {appData.business.name} and shall govern your access
                to and use of {appData.business.name}
                services which include all pages within {
                  appData.business.name
                }{" "}
                website, mobile applications and other products and services
                (collectively referred to as the “Services”). By signing up for
                an account on {appData.business.name} via our websites and/or
                services, you are deemed a customer and agree to these{" "}
                {appData.business.name} Terms and Conditions (“Terms”). You must
                not use any of the Services, if you have any objection to any of
                these Terms.
              </p>

              <h4>Age Restriction</h4>
              <p>
                Our website and services are not directed to children under 18.
                You are allowed to use the Services if you are an adult. We do
                not knowingly transact or provide any service to children under
                18.{" "}
              </p>

              <h4>Representation and Warranties</h4>
              <p>You represent and warrant to {appData.business.name} that:</p>
              <ul>
                <li>
                  You have full power and authority to enter into, execute,
                  deliver and perform this Agreement for your business/company;
                </li>
                <li>
                  you are duly organised, authorised and in good standing under
                  the laws of the Federal Republic of Nigeria or any state,
                  region or country of your organisation and are duly authorised
                  to do business in all other states, regions or countries in
                  which your business operates.
                </li>
              </ul>

              <h4>Intellectual Property</h4>
              <p>
                We do not grant any right or license to any{" "}
                {appData.business.name} intellectual property rights by
                implication, estoppel or otherwise other than those expressly
                mentioned in this Agreement.
              </p>

              <h4>Data Compliance</h4>
              <p>
                You agree to comply with all data privacy and security
                requirements of the Payment Card Industry Data Security Standard
                (PCI DSS Requirements”) and under any applicable law or
                regulation that may be in force, enacted or adopted regarding
                confidentiality, your access, use, storage and disclosure of
                user information. Information on the PCI DSS can be found on the
                PCI Council’s website. It is your responsibility to comply with
                these standards. Our payment gateway is responsible for the
                security and protection of Card Holder Data (CHD) processed
                through the Services. We acknowledge that you own all your data.
                You hereby grant {appData.business.name} a perpetual,
                irrevocable, sub-licensable, assignable, worldwide, royalty-free
                license to use, reproduce, electronically distribute, and
                display your data for the following purposes:
              </p>
              <ol>
                <li>Providing and improving our services</li>
                <li>
                  Internal usage, including but not limited to, data analytics
                  and metrics so long as individual customer data has been
                  anonymized and aggregated with other customer data;{" "}
                </li>
                <li>
                  Complying with applicable legal requirements and assisting law
                  enforcement agencies by responding to requests for the
                  disclosure of information in accordance with local laws; and{" "}
                </li>
                <li>
                  Any other purpose for which consent has been provided by your
                  you.
                </li>
              </ol>

              <h4>License to Use Our Services</h4>
              <p>
                We grant you a non-assignable, non-exclusive and revocable
                license to use the Services permitted by these terms. This
                license grant includes all updates, upgrades, new versions and
                replacement software for you to use in connection with our
                services. If you do not comply with the documentation and any
                other requirements provided by {appData.business.name}, then you
                will be liable for all resulting damages suffered by you,{" "}
                {appData.business.name} and third parties. Unless otherwise
                provided by applicable law, you agree not to alter, re-design,
                reproduce, adapt, display, distribute, translate, disassemble,
                reverse engineer, or otherwise attempt to create any source code
                that is derived from the software. Upon expiration or
                termination of this Agreement, you will immediately cease all
                use of any Software.
              </p>

              <h4>Publicity</h4>
              <p>
                You hereby grant {appData.business.name} permissions to use your
                name and logo in our marketing materials including, but not
                limited to use on our website, in customer listings, in
                interviews and in press releases. Such publicity does not imply
                an endorsement for your products and services.{" "}
              </p>

              <h4>Our Fees and Pricing Schedule</h4>
              <p>
                You agree to pay us for the services we render as a payment
                gateway and automation provider for your goods and services. Our
                Fees will be calculated as demonstrated on the Pricing page on
                the website/app. The Fees on our Pricing page is integral to and
                forms part of this Agreement. We reserve the right to revise our
                Fees. In the event that we revise our fees we will notify you
                within 3 days of such change{" "}
              </p>

              <h4>Indemnification</h4>
              <p>
                You hereby indemnify {appData.business.name} and undertake to
                keep {appData.business.name}, its staff and affiliates
                indemnified against any losses, damages, costs, liabilities and
                expenses (including without limitation reasonable legal fees and
                expenses) arising out of any breach by you of any provision of
                these Terms, or arising out of any claim that you have breached
                any provision of these Terms. You will indemnify and hold
                {appData.business.name} harmless from and against any claim,
                suit or proceedings brought against {appData.business.name}{" "}
                arising from or in connection with violations of Intellectual
                Property or other rights of third parties in relation to your
                use of the Services.
              </p>

              <h4>Breaches of these Terms</h4>
              <p>
                Without prejudice to {appData.business.name}’s other rights
                under these Terms, if you breach these Terms in any way,{" "}
                {appData.business.name} may take such action as
                {appData.business.name} deems appropriate to deal with the
                breach, including suspending your access to your account,
                debiting data, airtime and Naira wallets, prohibiting you from
                accessing the website, blocking computers using your IP address
                from accessing the website, contacting your internet service
                provider to request that they block your access to the website
                and/or bringing court proceedings against you.
              </p>

              <h4>Notification of Errors</h4>
              <p>
                You agree to notify us immediately any error is detected while
                reconciling transactions that have occurred using the Services.
                We will investigate and rectify the errors where verified. In
                the event that we notice any errors, we will also investigate
                and rectify such errors. Where we owe you money as a result of
                such errors, we will refund the amounts owed to you by a bank
                transfer to your Bank Account or airtime or data wallet. If a
                transaction is erroneously processed through your platform,
                report to us immediately. We will investigate any such reports
                and attempt to rectify the errors by crediting or debiting your
                data, airtime or Naira wallets as appropriate. Failure to notify
                us within 5 (five) days of the occurrence of an error will be
                deemed a waiver of your rights to amounts that are owed to you
                due to an error.
              </p>

              <h4>Security and Fraud Controls</h4>
              <p>
                You agree to use measures that are appropriate for your usage to
                reduce the risk of fraud. In the event that you suspect any
                fraudulent activity by a customer, you agree to notify{" "}
                {appData.business.name}
                immediately and quit the delivery of the service. In addition,
                where we suspect that there have been frequent fraudulent
                transactions on your account, we reserve the right to cancel our
                service to you and/or your account
              </p>

              <h4>Termination</h4>
              <p>
                You may terminate this Agreement by closing your{" "}
                {appData.business.name} Account. We may suspend your{" "}
                {appData.business.name} Account and your access to{" "}
                {appData.business.name} services and any funds, or terminate
                this Agreement, if;
              </p>
              <ol>
                <li>
                  You do not comply with any of the provisions of this
                  Agreement;
                </li>
                <li>We are required to do so by a Law; </li>
                <li>
                  We are directed by a Card Network or issuing financial
                  institution; or
                </li>
                <li>Where a suspicious or fraudulent transaction occurs</li>
              </ol>

              <h4>Privacy Policy</h4>
              <p>
                {appData.business.name} is committed to managing your Personal
                Information in line with global industry best practices. You
                should read our Privacy Policy to understand how we use your
                information and the steps we take to protect your information.
              </p>

              <h4>Governing Law</h4>
              <p>
                These Terms shall be interpreted and governed in accordance with
                the Laws of the Federal Republic of Nigeria and you submit to
                the non-exclusive jurisdiction of the Courts located in Nigeria
                for the resolution of any dispute
              </p>

              <h4>Information Security and Warranty Disclaimer</h4>
              <p>
                {appData.business.name} WILL ALWAYS ENSURE THAT THE WEBSITE IS
                AVAILABLE AT ALL TIMES AND BUG FREE. HOWEVER, IT IS USED AT YOUR
                OWN RISK. WE PROVIDE ALL MATERIALS “AS IS” WITH NO WARRANTY,
                EXPRESS OR IMPLIED, OF ANY KIND. WE EXPRESSLY DISCLAIM ANY AND
                ALL WARRANTIES AND CONDITIONS, INCLUDING ANY IMPLIED WARRANTY OR
                CONDITION OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AVAILABILITY, SECURITY, TITLE, AND NON-INFRINGEMENT OF
                INTELLECTUAL PROPERTY RIGHTS, WITHOUT LIMITING THE GENERALITY OF
                THE FOREGOING, {appData.business.name} MAKES NO WARRANTY THAT
                OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR
                WEBSITE WILL REMAIN FREE FROM ANY INTERRUPTION, BUGS,
                INNACCURACIES, AND ERROR FREE. YOUR USE OF OUR SERVICES ARE AT
                YOUR OWN RISK AND YOU ALONE WILL BE RESPONSIBLE FOR ANY DAMAGE
                THAT RESULTS IN LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM.
                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN OBTAINED BY
                YOU FROM OUR WEBSITE OR OUR SERVICES WILL CREATE ANY WARRANTY OR
                CONDITION NOT EXPRESSLY STATED. You are responsible for
                configuring your information technology, computer programmes and
                platform in order to access our Services. Please ensure you use
                your virus protection software or application as we cannot
                guarantee that our Services will be free from viruses or bugs.
                You must not attempt to gain unauthorized access to our
                Services, computers or databases. You must not misuse our
                Services by introducing trojans, viruses or other materials
                which are malicious or technologically harmful.
              </p>

              <h4>Limitation of Liability</h4>
              <p>
                YOUR USE OF {appData.business.name} SITE AND SERVICES IS AT YOUR
                OWN RISK. YOU AGREE TO THE LIMITATION LIABILITY CLAUSE TO THE
                MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW:{" "}
                {appData.business.name} WILL IN NO WAY BE LIABLE FOR ANY DIRECT,
                INDIRECT, INCIDENTAL PUNITIVE, CONSEQUENTIAL, SPECIAL OR
                EXEMPLARY DAMAGES OR ANY DAMAGES INCLUDING DAMAGES RESULTING
                FROM REVENUE LOSS, PROFIT LOSS, USE, DATA, GOODWILL , BUSINESS
                INTERRUPTION OR ANY OTHER INTANGIBLE LOSSES (WHETHER{" "}
                {appData.business.name} HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES OR NOT) ARISING OUT OF {appData.business.name}’S
                WEBSITE OR SERVICES (INCLUDING, WITHOUT LIMITATION TO INABILITY
                TO USE, OR ARISING FROM THE RESULT OF USE OF{" "}
                {appData.business.name}’S WEBSITE OR SERVICES) WHETHER SUCH
                DAMAGES ARE BASED ON WARRANTY, TORT, CONTRACT, STATUTE OR ANY
                OTHER LEGAL THEORY. SOME JURISDICTIONS DO NOT ALLOW EXCLUSION OF
                CERTAIN WARRANTIES OR LIMITATIONS ON THE SCOPE AND DURATION OF
                SUCH WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU
                IN THEIR ENTIRITIES, BUT WILL APPLY TO THE MAXIMUM EXTENT
                PERMITTED BY APPLICABLE LAW
              </p>

              <h4>Updates, Modifications and Amendments</h4>
              <p>
                We may need to update, modify or amend our Terms and Conditions
                as our technology evolves. We reserve the right to make changes
                to this Terms and Conditions at any time by giving notice to
                users on this page. We advise that you check this page often,
                referring to the date of the last modification on the page If
                you have any objection to any of the changes to this Terms and
                Conditions, you must cease using our website and/or services
                immediately.
              </p>

              <h4>Miscellaneous</h4>
              <p>
                You agree that all agreements, notices, disclosures and other
                communications that we provide to you electronically satisfy any
                legal requirement that such communications be in writing.
                Assigning or sub-contracting any of your rights or obligations
                under these Terms of Use to any third party is prohibited. We
                reserve the right to transfer, assign or sub-contract the
                benefit of the whole or part of any rights or obligations under
                these Terms of Use to any third party.
              </p>

              <h4>Restricted Activities</h4>
              <p>
                You may not use {appData.business.name} in connection with any
                product, service, transaction or activity that:
              </p>

              <ol>
                <li>
                  Violates any law or government regulation, or promotes or
                  facilitates such by third parties;
                </li>
                <li>Is fraudulent, deceptive, unfair or predatory;</li>
                <li>
                  Causes or threatens reputational damage to us or any Card
                  Network;
                </li>
              </ol>

              <h4>Acceptable Use Policy</h4>
              <p>
                By accessing or using {appData.business.name}, you agree to
                comply with the terms and conditions of this Acceptable Use
                Policy.
              </p>
              <br />
            </div>
            <button
              type="button"
              className="rounded-b-lg  py-3 text-white bg-primary-black hover:bg-gray-100 focus:bg-gray-100 focus:ring-offset-indigo-200  w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2   flex justify-center items-center flex-col "
              onClick={(e) => {
                toggle();
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(Terms);
