import React, { useContext, useEffect, useRef, useState } from "react";
import { AppDataContext } from "../providers/appData";
import { FormContext } from "../providers/formValues";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

function Sliders() {
  const [extraHTML, setExtraHTML] = useState("");
  const { formData, formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);

  useEffect(() => {
    const shouldFetchAppData =
      !appData.timestamp || new Date().getTime() - appData.timestamp > 1800000;

    // if (!extraHTML || shouldFetchAppData) fetchExtraHTML();
    appData.adImage1 &&
      (!localStorage.getItem("ad_image1") || shouldFetchAppData) &&
      getAd(1);
    appData.adImage2 &&
      (!localStorage.getItem("ad_image2") || shouldFetchAppData) &&
      getAd(2);
  }, []);

  const fetchExtraHTML = () => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "hasFetechedExtraHTML", value: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "html", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        try {
          setExtraHTML(response);
          return;
        } catch (e) {}
        setExtraHTML(response);
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "extraHTML", value: response },
        });
      });
  };

  const getAd = (index) => {
    formDispatch({
      type: "SET_FORM_DATA",
      data: { name: "hasFetechedExtraHTML", value: true },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(localStorage.getItem("apiURL") + "ad_image" + index, requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        try {
          localStorage.setItem("ad_image" + index, response);
          return;
        } catch (e) {}
        setExtraHTML(response);
        formDispatch({
          type: "SET_FORM_DATA",
          data: { name: "extraHTML", value: response },
        });
      });
  };

  return (
    <div
      className="  mt-4 md:mt-8  md:max-w-sliderWidth"
      style={{ width: "90vw" }}
    >
      <Slide
        arrows={false}
        easing="linear"
        duration={0}
        transitionDuration={10000}
      >
        {appData.adImage1 && localStorage.getItem("ad_image1") && (
          <div className="ml-2 mr-2 ">
            <img
              className="h-auto max-w-full  shadow-lg rounded-lg "
              src={
                "data:image/png;base64, " + localStorage.getItem("ad_image1")
              }
              style={{ maxHeight: "150px" }}
              alt="1"
              onClick={() => {
                appData.settings.ad1_url && localStorage.getItem("isMobileApp")
                  ? window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        command: "openExternalUrl",
                        data: appData.settings.ad1_url,
                      })
                    )
                  : window.open(appData.settings.ad1_url, "_blank");
              }}
            />
          </div>
        )}
        {appData.adImage1 && localStorage.getItem("ad_image2") && (
          <div className="ml-2 mr-2 ">
            <img
              className="h-auto max-w-full  shadow-lg rounded-lg "
              src={
                "data:image/png;base64, " + localStorage.getItem("ad_image2")
              }
              style={{ maxHeight: "150px" }}
              alt="1"
              onClick={() => {
                appData.settings.ad2_url && localStorage.getItem("isMobileApp")
                  ? window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        command: "openExternalUrl",
                        data: appData.settings.ad2_url,
                      })
                    )
                  : window.open(appData.settings.ad2_url, "_blank");
              }}
            />
          </div>
        )}
      </Slide>
    </div>
  );
}

export default Sliders;
