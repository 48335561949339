import { useState, useEffect, useContext } from "react";
import loadingSmall from "../icons/loadingSmall.svg";
import headphone from "../icons/headphone.svg";
import info from "../icons/info-circle.svg";
import eye from "../icons/eye.svg";
import eyeclosed from "../icons/eye-blocked.svg";
import { Link, useHistory } from "react-router-dom";
import { UserContext } from "../providers/userData";
import { FormContext } from "../providers/formValues";
import { AppDataContext } from "../providers/appData";
import Alert from "../components/Alert";
import Api from "../../src/helper/Api";
function LoginPage() {
  let history = useHistory();

  const { user, userDispatch } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [focused, setFocused] = useState(false);
  const [sending, setSending] = useState(false);
  const [showPassword, setshowPassword] = useState(true);

  document.title = "Sign In-" + appData.business.name;
  user.data && history.push("/");
  const handleFocus = (e) => {
    setFocused(true);
  };

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };
  const submitForm = (e) => {
    e.preventDefault();
    setSending(true);
    Api(
      "post",
      "signin",
      { identifier: formData.identifier, password: formData.password },
      onLogin,
      onFail
    );
  };
  function onLogin(data) {
    userDispatch({ type: "STORE_USER_DATA", user: data });
    history.push("/");
  }
  function onFail(error) {
    setSending(false);
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: {
          isOpen: true,
          message: error,
          type: "error",
        },
      },
    });
  }

  useEffect(() => {
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: {
          isOpen: false,
          message: "",
        },
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "identifier",
        value: "",
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "password",
        value: "",
      },
    });
  }, []);

  return (
    <>
      {appData && (
        <div className="bg-gradient-to-r w-screen h-full py-28 flex flex-col items-center justify-center from-primary-orange to-primary-black  dark:text-white ">
          <div className=" rounded-2xl shadow  dark:bg-gray-900 bg-white">
            {formData.Alert && <Alert message={formData.Alert.message} />}
            <Link to="/">
              <div className="   h-5 flex justify-center items-center p-6  mt-10">
                <img
                  src={window.location.origin + "/logo.png"}
                  alt="logo"
                  className=""
                  style={{ height: 46 }}
                />
                <h1 className="font-medium text-lg md:text-2xl text-primary-orange text-center    ">
                  {appData.business.name}
                </h1>
              </div>
            </Link>
            <div className="flex flex-col max-w-sm px-4 pt-2  sm:px-6 md:px-8 lg:px-10">
              <h2 className="text-primary-orange font-medium text-2xl mt-12 text-center ">
                Let's get started.
              </h2>
              <p className="text-xs text-primary-gray font-medium mt-3.5"></p>
              <form onSubmit={submitForm}>
                <div className="relative w-full mt-12">
                  <label className="block   text-sm font-medium mb-1">
                    Email address / Phone number
                    <input
                      type="text"
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                      placeholder="Email or Phone Number"
                      focused={focused.toString()}
                      required
                      onBlur={handleFocus}
                      name="identifier"
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      value={formData.identifier}
                    />
                    <span>Please enter correct Email or Phone Number </span>
                  </label>
                  <label className="block   text-sm font-medium mt-5">
                    Password
                    <input
                      type={showPassword ? "password" : "text"}
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      focused={focused.toString()}
                      required
                      name="password"
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      value={formData.password}
                    />
                    {showPassword ? (
                      <img
                        src={eye}
                        alt=""
                        className="absolute right-2 bottom-3 h-5 w-5 "
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <img
                        src={eyeclosed}
                        alt=""
                        className="absolute right-2 bottom-3 h-5 w-5 "
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                      />
                    )}
                    <span className="absolute">
                      Please enter your password{" "}
                    </span>
                  </label>
                </div>
                <Link
                  to="/forgotPassword"
                  className="block  text-primary-black text-sm font-semibold mt-4 text-right "
                >
                  Forgot your password?
                </Link>
                <div className="flex w-full mt-10">
                  <button
                    type="submit"
                    disabled={sending}
                    className="py-2 px-4 bg-primary-orange  active:bg-yellow-100 active:ring-primary-orange text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-lg "
                  >
                    {sending ? (
                      <div className="flex items-center justify-center">
                        <img
                          src={loadingSmall}
                          alt="loading ..."
                          className="w-7 h-7 "
                        />
                      </div>
                    ) : (
                      `Login`
                    )}
                  </button>
                </div>
              </form>
              <div className="justify-center text-sm text-center text-gray-500 flex-items-center  mt-12">
                Don't have an account?&nbsp;
                <Link to="/signUp" className="text-sm  text-primary-orange  ">
                  Create an Account
                </Link>
              </div>
              <div className="flex items-center justify-center my-4 gap-2">
                <button
                  className="bg-primary-black flex gap-2  justify-center items-center w-32 text-white  font-medium text-xs uppercase  px-4 py-2.5 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => history.push("faq")}
                >
                  <img src={info} alt="info" /> FAQS
                </button>
                <button
                  className="bg-primary-black flex gap-2 justify-center items-center  w-32 text-white  font-medium  uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => history.push("contact")}
                >
                  <img src={headphone} alt="headphone" />
                  Contact&nbsp;Us
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginPage;
