import React, { useContext, useEffect, useState } from "react";
import eisalat from "../icons/9mobile.png";
import glo from "../icons/glo.png";
import airtel from "../icons/airtel.png";
import mtn from "../icons/mtn.png";
import { getOS } from "../helper/getOs";
import CurrencyFormat from "../helper/CurrencyFormat";
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "../providers/auth";
import { FormContext } from "../providers/formValues";
import { AppDataContext } from "../providers/appData";
import PlansModels from "../components/plansModels";
import NumberCheckModal from "../components/numberCheckModal";
import { UserContext } from "../providers/userData";
import PaymentType from "../components/paymentType";
import ConfirmationModel from "../components/confirmationModel";
import Layout from "../Layout";
import alert from "../icons/alert.svg";
import DisableNotification from "../components/DisableNotification";
import phonebook from "../icons/phonebook.png";
import ContactModal from "../components/ContactModal";

function PriceList() {
  const { setShowModal, showModal } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [networkErro, setnetworkErro] = useState("");
  const [planerro, setPlanerro] = useState("");
  const [focused, setFocused] = useState(false);
  const [CloseNotice, setCloseNotice] = useState(false);
  const [Notice, setNotice] = useState("");
  const [sending, setSending] = useState(false);

  document.title = "Prices -" + appData.business.name;

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const onPlanSelect = (network, atmPrice, walletPrice) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "network", value: network },
    });
  };

  const history = useHistory();

  useEffect(() => {
    if (formData.fromBeneficiaries) {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "fromBeneficiaries",
          value: false,
        },
      });
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "plans_id", value: "" },
      });
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "plans_name", value: "" },
      });
      setShowModal((preShowModel) => !preShowModel);
      return;
    }
  }, []);

  return (
    <Layout PageName={"Price List"}>
      <div className="">
        <h2 className="font-medium text-sm mt-9 md:text-lg">Select Network</h2>
        {CloseNotice ? (
          <DisableNotification Close={setCloseNotice} notification={Notice} />
        ) : (
          ""
        )}
        <ContactModal />
        <div className="flex gap-4 mt-2">
          <button
            className="flex  flex-col justify-center items-center "
            onClick={() => {
              appData.master_settings.MTN_data_master * 1 === 0
                ? onPlanSelect(
                    "MTN",
                    appData.airtime_plans.MTN.atm_price,
                    appData.airtime_plans.MTN.wallet_price
                  )
                : setCloseNotice(true);

              setNotice("MTN Data is not available now please try again later");

              setnetworkErro("");
            }}
          >
            <div
              onClick={() => {
                appData.master_settings.MTN_data_master * 1 === 0
                  ? setShowModal(!showModal)
                  : setShowModal(false);
              }}
              className={
                formData.network === "MTN"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.MTN_data_master * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img src={mtn} alt="mtn" className="md:h-28" />
            </div>
            <div className=" font-extrabold text-xs md:text-base mt-2.5">
              MTN
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.GLO_data_master * 1 === 0
                ? onPlanSelect(
                    "GLO",
                    appData.airtime_plans.GLO.atm_price,
                    appData.airtime_plans.GLO.wallet_price
                  )
                : setCloseNotice(true);

              setNotice("GLO Data is not available now please try again later");

              setnetworkErro("");
            }}
          >
            <div
              onClick={() => {
                appData.master_settings.GLO_data_master * 1 === 0
                  ? setShowModal(!showModal)
                  : setShowModal(false);
              }}
              className={
                formData.network === "GLO"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.GLO_data_master * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img src={glo} alt="glo" className="md:h-28" />
            </div>
            <div className=" font-extrabold text-xs md:text-base mt-2.5">
              GLO
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.AIRTEL_data_master * 1 === 0
                ? onPlanSelect(
                    "AIRTEL",
                    appData.airtime_plans.AIRTEL.atm_price,
                    appData.airtime_plans.AIRTEL.wallet_price
                  )
                : setCloseNotice(true);
              setNotice(
                "AIRTEL Data is not available now please try again later"
              );

              setnetworkErro("");
            }}
          >
            <div
              onClick={() => {
                appData.master_settings.AIRTEL_data_master * 1 === 0
                  ? setShowModal(!showModal)
                  : setShowModal(false);
              }}
              className={
                formData.network === "AIRTEL"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.AIRTEL_data_master * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img src={airtel} alt="airtel" className="md:h-28" />
            </div>
            <div className=" font-extrabold text-xs md:text-base mt-2.5">
              Airtel
            </div>
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.ETISALAT_data_master * 1 === 0
                ? onPlanSelect(
                    "ETISALAT",
                    appData.airtime_plans.ETISALAT.atm_price,
                    appData.airtime_plans.ETISALAT.wallet_price
                  )
                : setCloseNotice(true);
              setNotice(
                "9mobile Data is not available now please try again later"
              );

              setnetworkErro("");
            }}
          >
            <div
              onClick={() => {
                appData.master_settings.ETISALAT_data_master * 1 === 0
                  ? setShowModal(!showModal)
                  : setShowModal(false);
              }}
              className={
                formData.network === "ETISALAT"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.ETISALAT_data_master * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img src={eisalat} alt="etisalat" className="md:h-28" />
            </div>
            <div className=" font-extrabold text-xs md:text-base mt-2.5">
              9mobile
            </div>
          </button>
        </div>
        <p className="text-red-500">{networkErro}</p>
      </div>
    </Layout>
  );
}

export default PriceList;
