import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import eisalat from "../icons/9mobile.png";
import glo from "../icons/glo.png";
import airtel from "../icons/airtel.png";
import mtn from "../icons/mtn.png";
import copyIcon from "../icons/copy.svg";

import { toast } from "react-toastify";
import Layout from "../Layout";

function Balance() {
  const history = useHistory();

  const Copy = (text) => {
    navigator.clipboard.writeText(text).then(() => toast("Copied"));
  };

  return (
    <Layout PageName={"Balance Codes"}>
      <div className="flex justify-between  mt-12">
        <img src={mtn} alt="mtn" className=" h-28" />
        <div className="flex flex-col gap-4 ">
          <div className="flex gap-4 justify-end">
            <p className="text-xs font-medium md:text-lg">
              Normal Data and CG: *323*1#
            </p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*323*1#");
                }}
              />
            </div>
          </div>
          <div className="flex gap-4  justify-end">
            <p className="text-xs font-medium md:text-lg">SME Data: *323*3# </p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*323*3#");
                }}
              />
            </div>
          </div>

          <div className="flex  gap-4  justify-end">
            <p className="text-xs font-medium md:text-lg">Airtime: *310# </p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*310#");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="  flex justify-between mt-11">
        <img src={glo} alt="glo" className="h-28" />
        <div className=" flex-col flex gap-4">
          <div className="flex gap-4 justify-end">
            <p className="text-xs font-medium md:text-lg">Normal Data *323#</p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*323#");
                }}
              />
            </div>
          </div>
          <div className="flex  gap-4  justify-end">
            <p className="text-xs font-medium md:text-lg">Airtime: *310# </p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*310#");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-11">
        <img src={airtel} alt="airtel" className="h-28" />
        <div className=" flex-col flex gap-4">
          <div className="flex gap-4 justify-end">
            <p className="text-xs font-medium md:text-lg">Normal Data *323#</p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*323#");
                }}
              />
            </div>
          </div>
          <div className="flex  gap-4  justify-end">
            <p className="text-xs font-medium md:text-lg">Airtime: *310# </p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*310#");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-11">
        <img src={eisalat} alt="etisalat" className="h-28" />
        <div className=" flex-col flex gap-4">
          <div className="flex gap-4 justify-end">
            <p className="text-xs font-medium md:text-lg">Normal Data *323#</p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*323#");
                }}
              />
            </div>
          </div>
          <div className="flex  gap-4  justify-end">
            <p className="text-xs font-medium md:text-lg">Airtime: *310# </p>
            <div className="flex gap-1 align-middle items-center">
              <p className="text-xx  text-primary-gray md:text-xs">copy</p>
              <img
                src={copyIcon}
                alt="copy"
                className=""
                onClick={() => {
                  Copy("*310#");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Balance;
