import bell from "../icons/Bell.svg";
import LeftAngle from "../icons/LeftAngle.svg";
import Nav from "../components/nav";
import SpinnerSmall from "../icons/SpinnerSmall.svg";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/userData";
import { AppDataContext } from "../providers/appData";
import { FormContext } from "../providers/formValues";
import { useHistory } from "react-router-dom";
import Layout from "../Layout";

function Faq() {
  const { user, userDispatch } = useContext(UserContext);
  const { appData, dispatch } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  document.title = "FAQs- " + appData.business.name;
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const back = () => {
    history.push("/");
  };
  const fetchFAQS = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(localStorage.getItem("apiURL") + "faq", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setLoading(false);
        console.log(data);
        if (data.status === "success") {
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "faqs", value: data.data },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "SET_ERROR",
            data: errorString,
          });
        } else {
          formDispatch({
            type: "SET_ERROR",
            data: data.message,
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFAQS();
  }, []);

  return (
    <Layout PageName={"FAQ"}>
      <div>
        <section className="text-gray-700">
          <div className="container px-5 mx-auto">
            <div className="text-center mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium text-center title-font text-gray-700 dark:text-gray-200   mb-4">
                Frequently Asked Questions
              </h1>
            </div>
            <div className="flex flex-wrap lg:w-4/5 mx-auto sm:mb-2 items-center justify-center ">
              {loading ? (
                <div className="flex items-center justify-center">
                  <img src={SpinnerSmall} alt="loading ..." className="" />{" "}
                </div>
              ) : (
                <div className="w-full  px-4 py-2">
                  {formData.faqs && (
                    <>
                      {formData.faqs.map((faq) => (
                        <details className="mb-4">
                          <summary className="font-semibold text-gray-700 dark:text-gray-200   rounded-md py-2 px-4">
                            {faq.question}
                          </summary>

                          <div className="text-gray-700 dark:text-gray-200  ">
                            {faq.answer}
                          </div>
                        </details>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Faq;
