import wrong from "../icons/wrong.svg";

import { AppDataContext } from "../providers/appData";

import { useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";
import Layout from "../Layout";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Kyc404() {
  const { appData, dispatch } = useContext(AppDataContext);
  const history = useHistory();

  return (
    <Layout>
      <div className="text-gray-700  flex flex-col    items-center   light:bg-white h-[80vh] ">
        <img src={wrong} className="w-24 h-24 mt-16" alt="img" />
        <p className=" font-medium text-sm md:text-xl rounded bg-red-600  p-1 text-white text-center text-xs mt-10">
          The Feature you are trying to access requires you to verify your
          account before gaining access to it!
        </p>
        <Link to="kyc" className="text-center items-center">
          <p className="font-medium text-sm md:text-xl text-center mt-10 dark:text-white">
            CBN policies mandate us to verify your identity to keep your account
            safe and ensure you enjoy all features on {appData.business.name}.
          </p>
          <p className="cursor-pointer text-sm font-bold inline-block text-primary-orange text-center mt-3">
            Get Started ➔
          </p>
        </Link>
      </div>
    </Layout>
  );
}

export default Kyc404;
