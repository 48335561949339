import React, { useContext, useEffect, useState } from "react";

import close from "../icons/Close.svg";
import { FormContext } from "../providers/formValues";
import { AuthContext } from "../providers/auth";
import { AppDataContext } from "../providers/appData";
import { motion } from "framer-motion";

function ElecricityPlansModal() {
  const { formData, formDispatch } = useContext(FormContext);
  const { setShowModal, showModal } = useContext(AuthContext);
  const { appData, dispatch } = useContext(AppDataContext);
  let myNetwork;

  switch (formData.network) {
    case "AEDC":
      myNetwork = appData.electric_plans.AEDC;
      break;
    case "EKEDC":
      myNetwork = appData.electric_plans.EKEDC;
      break;
    case "IBEDC":
      myNetwork = appData.electric_plans.IBEDC;
      break;
    case "IKEDC":
      myNetwork = appData.electric_plans.IKEDC;
      break;
    case "JED":
      myNetwork = appData.electric_plans.JED;
      break;
    case "KAEDCO":
      myNetwork = appData.electric_plans.KAEDCO;
      break;
    case "KEDCO":
      myNetwork = appData.electric_plans.KEDCO;
      break;
    case "PHED":
      myNetwork = appData.electric_plans.PHED;
      break;
    case "EEDC":
      myNetwork = appData.electric_plans.EEDC;
      break;
    case "BEDC":
      myNetwork = appData.electric_plans.BEDC;
      break;
    default:
      myNetwork = "please select a disco";
  }
  // console.log(myNetwork);
  const inputPlansId = (plans_id, plans_name, commission) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "electricPlan_id", value: plans_id },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "plans_name", value: plans_name },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "commission", value: commission },
    });
    // setShowModal(false);
  };
  return (
    <div>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
        <div className="absolute w-full  mx-auto max-w-sm md:max-w-lg top-44 inset-x-0 p-4">
          {}
          {/*content*/}
          <motion.div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4"
            initial={{ opacity: 0.5, y: 500 }}
            animate={{ opacity: [0, 1], y: [500, -50] }}
            transition={{ doration: 1.5, type: "tween" }}
          >
            {/*header*/}
            <div className="flex py-4 rounded-t-lg">
              <button className=" bg-transparent border-0 text-right  outline-none focus:outline-none  h-6 w-5">
                <img src={close} alt="close" />
              </button>
              <h3 className="text-lg font-extrabold text-black text-center mx-auto">
                Meter Type
              </h3>
            </div>
            {/*body*/}
            <div className="relative flex flex-col text-black  mb-5">
              <table className="table p-4 bg-white shadow rounded-lg ">
                <tbody className="bg-secondary-blue">
                  {myNetwork.map((network) => (
                    <tr
                      className="text-secondary-black  border-b-8 border-white"
                      key={network.id}
                      onClick={() => {
                        inputPlansId(
                          network.id,
                          network.name,
                          network.commission,
                        );
                      }}
                    >
                      <td className=" font-medium text-sm p-2">
                        {`${network.name} (${network.commission}% commission)`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/*footer*/}
          </motion.div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
    </div>
  );
}

export default ElecricityPlansModal;
