import React, { useContext, useState, useEffect } from "react";
import error from "../icons/wrong.svg";
import success from "../icons/right.svg";
import contact from "../icons/headphone.svg";
import wallet from "../icons/wallet-add.svg";
import receipt from "../icons/receipt-item.svg";
import home from "../icons/home.svg";
import erwee from "../icons/info-circle.svg";
import retry from "../icons/refresh-2.svg";
import { AppDataContext } from "../providers/appData";
import { getOS } from "../helper/getOs";
import { FormContext } from "../providers/formValues";
import { Link, useHistory } from "react-router-dom";
import BeneficiaryModal from "./beneficiaryModal";
import fingerprint from "../icons/fingerprint.svg";
import faceId from "../icons/faceId.png";

const Alert = ({ color, message }) => {
  const history = useHistory();

  const { appData, dispatch } = useContext(AppDataContext);

  const { formData, formDispatch } = useContext(FormContext);
  const PrintRecipt = () => {
    history.push("/transactions");
  };

  const openBeneficiaryModel = () => {
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "BeneficiaryModal",
        // change it to false
        value: { isOpen: true },
      },
    });
  };
  const clearModal = () => {
    if (!formData.Alert.stayOnClose) {
      formData.Alert.location
        ? history.push(`/${formData.Alert.location}`)
        : history.push("/");
    }
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: { isOpen: false, message: "", type: "", stayOnClose: false },
      },
    });
  };

  // Function  to enroll tip! check for device capability before enrollment
  const enrollBiometric = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ command: "enrollBiometric", data: formData.password })
    );
  };

  //function to check for enrollment
  const isBiometricEnrolled = () => {
    const enrollmentStatus = window.localStorage.getItem("biometricEnrolled");
    if (enrollmentStatus == null) {
      return false;
    }
    return true;
  };

  return (
    <>
      {formData.BeneficiaryModal ? <BeneficiaryModal /> : ""}
      {formData.Alert.isOpen &&
        (formData.Alert.type === "error" ? (
          <div>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-full">
              <div className="relative w-full my-6 mx-auto max-w-sm ">
                {/*content*/}

                <div className="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
                  <div className="w-full h-full text-center">
                    <div className="flex h-full flex-col justify-between">
                      <img src={error} alt="" className="h-24 " />
                      <p className="text-gray-800  text-xl font-medium mt-4">
                        ERROR
                      </p>
                      <p className="text-gray-700 dark:text-white text-xs py-2 px-6">
                        {message}
                      </p>
                      {!formData.Alert.hideHomeBtn && (
                        <div className="flex items-center justify-between gap-4 w-full mt-8">
                          <button
                            type="button"
                            className="py-8 px-4  bg-slate-200 hover:bg-gray-100 focus:ring-indigo-500 focus:ring-offset-indigo-200  w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg  "
                          >
                            <a
                              target="_"
                              href={
                                getOS() === "WEB"
                                  ? "https://web.whatsapp.com/send?phone=234" +
                                    appData.settings.contact_whatsapp_number +
                                    "&text=Hello!"
                                  : "https://api.whatsapp.com/send?phone=234" +
                                    appData.settings.contact_whatsapp_number +
                                    "&text=Hello!"
                              }
                              className="flex justify-center items-center flex-col"
                            >
                              <img
                                src={contact}
                                alt=""
                                className="bg-slate-700 p-2 rounded-2xl "
                              />
                              Report this issue
                            </a>
                          </button>
                          <button
                            type="button"
                            className="py-10 px-4  bg-slate-200 hover:bg-gray-100 focus:bg-gray-100 focus:ring-offset-indigo-200  w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg flex justify-center items-center flex-col "
                            onClick={() => clearModal()}
                          >
                            <img
                              src={home}
                              alt=""
                              className="bg-slate-700 p-2 rounded-2xl "
                            />
                            Home
                          </button>
                        </div>
                      )}
                      <div className="flex items-center justify-center mt-4 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          className={
                            " bg-primary-orange text-white w-full font-medium uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none  mb-1 ease-linear transition-all duration-150"
                          }
                          type="button"
                          onClick={() =>
                            formDispatch({
                              type: "INPUTVALUES",
                              data: {
                                name: "Alert",
                                value: { isOpen: false, message: "", type: "" },
                              },
                            })
                          }
                        >
                          Retry
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </div>
        ) : (
          <div>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-full">
              <div className="relative w-full my-6 mx-auto max-w-sm ">
                {/*content*/}

                <div className="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
                  <div className="w-full h-full text-center">
                    <div className="flex h-full flex-col justify-between">
                      <img src={success} alt="" className="h-24" />
                      <p className="text-gray-800 text-xl font-medium mt-4">
                        SUCCESS
                      </p>
                      <p className="text-gray-700  text-xs px-6  dark:text-white">
                        {message}
                      </p>

                      {formData.Alert.show === true && (
                        <>
                          <div className=" grid grid-cols-2 justify-between gap-4 w-full mt-3">
                            <button
                              type="button"
                              className="py-8 px-4  bg-slate-200 hover:bg-gray-100 focus:ring-indigo-500 focus:ring-offset-indigo-200 w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg   flex justify-center items-center flex-col   "
                              onClick={openBeneficiaryModel}
                            >
                              <img
                                src={wallet}
                                alt=""
                                className="bg-slate-700 p-2 rounded-2xl fill-white "
                              />
                              Add to Beneficiaries
                            </button>

                            <button
                              type="button"
                              className="py-10 px-4  bg-slate-200 hover:bg-gray-100 focus:bg-gray-100 focus:ring-offset-indigo-200  w-full transition ease-in duration-200 text-center text-xs font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg flex justify-center items-center flex-col "
                              onClick={() => {
                                PrintRecipt();
                              }}
                            >
                              <img
                                src={receipt}
                                alt=""
                                className="bg-slate-700 p-2 rounded-2xl"
                              />
                              Print Receipt
                            </button>
                          </div>
                        </>
                      )}
                      {/* display biometric option */}
                      {window.localStorage.getItem("isMobileApp") &&
                        !isBiometricEnrolled() &&
                        (formData.biometricType === "Fingerprint" ||
                          formData.biometricType === "FaceId") && (
                          <div
                            onClick={(e) => {
                              enrollBiometric();
                            }}
                          >
                            <img
                              src={
                                formData.biometricType === "Fingerprint"
                                  ? fingerprint
                                  : faceId
                              }
                              alt=""
                              className="w-12 h-12 mt-3   mx-auto  rounded-lg  p-1  dark:bg-white "
                            />
                            <p className="text-gray-700  text-xs px-6 underline  dark:text-white">
                              {"Enable " + formData.biometricType + " Payment"}
                            </p>
                          </div>
                        )}

                      <div className="flex items-center justify-center mt-4 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          className={
                            " bg-primary-orange text-white w-full font-medium uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none  mb-1 ease-linear transition-all duration-150"
                          }
                          type="button"
                          onClick={() => {
                            clearModal();
                          }}
                        >
                          CLOSE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </div>
        ))}
    </>
  );
};

export default Alert;
