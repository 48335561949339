import axios from "axios";
import { getOS } from "./getOs";

const Api = (
  method,
  url,
  data,
  callback,
  failback,
  token = null,
  history = null
) => {
  axios({
    method: method,
    url:
      url.length == 0
        ? localStorage
            .getItem("apiURL")
            .substring(0, localStorage.getItem("apiURL").length - 1)
        : localStorage.getItem("apiURL") + url,
    data: data && { source: getOS(), ...data },
    headers:
      token != null
        ? {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + token,
          }
        : {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
  })
    .then((response) => {
      const data = response.data;
      if (response.status === 200) {
        callback(data);
      } else {
        failback(data);
      }
    })
    .catch((error) => {
      if (!error.response) {
        failback("unable to connect to server");
      } else {
        if (
          error.response.data.message === "Token Expired" ||
          error.response.data.message === "User Not Found"
        ) {
          if (history != null) history.push("/signout");
        } else failback(error.response.data.message);
      }
    });
};
export default Api;
